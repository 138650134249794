.dreal-page-name {
    position: relative;
    background-color: #7B5099;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}
.dreal-top {
    text-align:center;
    margin: -60px auto 10px auto;
    position: relative;
    padding: 10px;
    background-image: url(/public/img/pneu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: #515151;
      font-size: 40px;
      font-weight: bold;
      line-height: 40px;
      margin-right: 1px;
      text-shadow: 4px 2px #cecece;
      text-transform: uppercase;
      border-bottom: 2px dotted #ffffff;
    }
}
#dreal-mosaic {
  position: relative;
  text-align: center;
}
#dreal1 {
    background-image: url('/public/img/dreal/dreal1.jpg');
}
#dreal2 {
    background-image: url('/public/img/dreal/dreal2.jpg');
}
#dreal3 {
    background-image: url('/public/img/dreal/dreal3.jpg');
}
#dreal4 {
    background-image: url('/public/img/dreal/dreal4.jpg');
}
#mosaic-container .img {
    display: inline-block;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    width: 49%;
}
.img_content,
#mosaic-container .img img {
  display: block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 340px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#mosaic-container .img p {
  background-color: rgba(19, 19, 20, 0.6);
  bottom: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 27px;
  left: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
}
#dreal-mosaic .cercle {
    background-color: #8dbb05;
    border: 10px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    display: inline-block;
    font-size: 27px;
    font-weight: bold;
    height: 240px;
    left: 0;
    line-height: 30px;
    margin: 0 auto;
    overflow-wrap: break-word;
    padding-top: 80px;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 33%;
    white-space: normal;
    width: 240px;
    z-index: 25;
}
#mosaic-container .overlay {
    background: rgba(19, 19, 20, 0.6);
    display: block;
    height: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    width: 100%;
}
#mosaic-container .img:hover p {
  visibility: hidden;
}
#mosaic-container .img:hover .overlay {
    overflow: visible;
    transition: all 0.5s;
}
#mosaic-container a.expand {
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    line-height: initial;
    padding: 10px 115px;
    position: absolute;
    z-index: 100;
}
#mosaic-container a.expand:hover {
    text-decoration: none;
    color: #ffffff;
}
#mosaic-container .overlay a.expand.big,
#mosaic-container .overlay a.expand {
  left: 0;
  right: 0;
}
#mosaic-container .overlay a.expand.big {
    font-size: 16px;
    top: 10px;
}
#mosaic-container .overlay a.expand {
  top: 40%;
}
#mosaic-container .img:hover .overlay {
  height: 100%;
}
@media only screen and (max-width: 1375px) {
    a.expand {
      padding: 10px 114px;
    }
    #mosaic-container .overlay a.expand.big {
      font-size: 15px;
    }
}
@media only screen and (max-width: 1285px) {
    .img_content {
      height: 365px;
    }
}
@media only screen and (max-width: 1220px) {
    #mosaic-container .overlay a.expand {
      font-size: 18px;
    }
    #dreal-mosaic .cercle {
      font-size: 22px;
      height: 200px;
      padding-top: 56px;
      top: 36%;
      width: 200px;
    }
    a.expand {
      padding: 10px 95px;
    }
}
@media only screen and (max-width: 1170px) {
    .img_content {
      height: 390px;
    }
    #mosaic-container .overlay a.expand.big {
      font-size: 13px;
      padding-top: 20px;
    }
}
@media all and (max-width: 925px) {
    #dreal-mosaic .cercle {
        border: medium none;
        border-radius: 0;
        height: auto;
        margin-bottom: 10px;
        padding: 20px 10px;
        position: initial;
        top: auto;
        width: 100%;
    }
    #dreal-mosaic .cercle br{
        display: none;
    }
    #mosaic-container .img {
      width: 100%;
    }
    #mosaic-container .img:nth-child(2n+1) {
      margin-right: 0;
    }
    .img_content {
      height: 265px;
    }
    #mosaic-container .overlay a.expand {
      font-size: 15px;
      text-align: center;
      padding: 10px 25px;
    }
    #mosaic-container .overlay a.expand.big {
      font-size: 15px;
      padding-top: 10px;
    }
}
@media all and (max-width: 640px) {
    .img_content {
      height: 300px;
    }
    #mosaic-container .overlay a.expand {
      padding: 10px;
    }
    #mosaic-container .overlay a.expand.big {
      top: 0;
    }
}
@media all and (max-width: 460px) {
    #mosaic-container .img p {
      font-size: 20px;
    }
    .img_content {
      height: 380px;
    }
}
@media all and (max-width: 365px) {
    #mosaic-container .overlay a.expand.big {
      font-size: 14px;
    }
}
