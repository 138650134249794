.contact-page-name {
    position: relative;
    background-color: #97be1e;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}

.contact-form-container {
    position: relative;
    max-width:1050px;
    margin: 0 auto 30px auto;

    button.btn {
        width: 100%;
        color: #fff;
        background-color: #5b2580;
        border-color: #5b2580;
        padding: 10px;
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
        margin-top: 20px;
        opacity: 1;
        transition: opacity .3s, padding .3s, margin .3s;
        cursor: pointer;

        &:hover {
            opacity: 0.9;
            padding: 15px 10px;
            margin-top: 15px;
            transition: opacity .3s, padding .3s, margin .3s;
            
        }
    }
}