.logs-container {
    position: relative;

    .logs-options {
        display: flex;
        justify-content: flex-end;
        padding: 5px 0;

        .log-option {
            text-align: center;
            opacity: 1;
            padding: 5px;
            transition: opacity .3s;
            border: 1px solid $grey;
            margin: 0 0 0 5px;
            font-size: 12px;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
                transition: opacity .3s;
            }
        }
    }

    .log-item {
        position: relative;
        margin-bottom: 5px;

        .log-title {
            position: relative;
            border: 1px solid $grey;
            padding: 0;
            display: flex;
            align-items: center;
            
            .log-title-open-content {
                width: 40px;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;
                i{
                    transform: rotate(0deg);
                    transition: transform .3s;
                }

                &:hover {
                    opacity: .5;
                    transition: opacity .3s;
                }
            }
            .log-title-checkbox {

                width: 40px;
                padding: 10px;
                text-align: center;
                margin: 0;

                border-right: 1px solid $grey;

                input {
                    margin: 0;
                }
            }

            .log-title-date {
                width: 100%;
                padding: 10px;
            }
            .log-title-size {
                width: 150px;
                text-align: right;
                padding: 10px;
                border-left: 1px solid $grey;
                font-size: 14px;
            }

            .log-title-option {
                text-align: center;
                opacity: 1;
                padding: 5px;
                transition: opacity .3s;
                border: 1px solid $grey;
                margin: 0 5px;
                font-size: 12px;
                cursor: pointer;

                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
        }

        .log-content {
            position: relative;
            border: 1px solid $grey;
            display: none;
            border-top: 0;
            padding: 10px;
        }

        .log-title-open {
            .log-title-open-content {
                i {
                    transform: rotate(90deg);
                    transition: transform .3s;
                }
            }
        }
    }
}