#user-navbar {
    border-bottom: 1px solid #e2e2e2;
    padding: 0 1rem;
}

#user-dashboard,
#dashboard-footer {
    display: flex;
    flex-wrap: wrap;
}
div.user-dashboard {
    padding: 1em;
    min-height: 500px;
    text-align: center;
}
div.user-dashboard h4 {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 100%;
    text-transform: uppercase;
    font-size: 22px;
    //font-weight: bold;
    color: black;
}
div.user-dashboard h4:after {
    display: block;
    margin-top: 15px;
    content: url("/public/img/dashboard/chevron.png");
}
div.user-dashboard div {
    height: 100%;
    //border-radius: 1em;
    background-color: #FFFFFF;
    padding: 40px 0 0 0;
    //box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    border: 1px solid #e2e2e2;

    //background-color: red;
    animation-name: stretch;
    animation-duration: .5s; 
    animation-timing-function: ease-out; 
    animation-delay: 0;
    animation-direction: alternate;
    //animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}
div.user-dashboard div img {
    height: 140px;
}
div.user-dashboard li {
    border-bottom: 1px solid #cdcdcd;
}
div.user-dashboard li:last-child {
    border-bottom: none;
}
#user_dashboard div.user-dashboard li.purple-hover {
    border: 1px solid #e2e2e2;
    margin: 3px 10px;
    background-color: white;
    transition: background .3s;

    &:hover {
        background-color: $andcopurple;
        transition: background .3s;
    }
}

#user_dashboard div.user-dashboard li.green-hover {
    border: 1px solid #e2e2e2;
    margin: 3px 10px;
    background-color: white;
    transition: background .5s;

    &:hover {
        background-color: $andcogreen;
        transition: background .5s;
    }
}


div.user-dashboard li a {
    font-size: 16px;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.user-dashboard li:hover a,
div.user-dashboard li:hover a .badge {
    text-decoration: none;
    color: white;
}
.user-dashboard .badge,
.footer-dashboard .badge {
    border: 0;
    border-radius: 0;
    padding: 3px 10px;
    margin: 0 5px;
    //border-radius: 50%;
    border: 1px solid #e2e2e2;
}


#offer-search-form {
    background-color: $andcogreen;
    width: 100%;
    padding: 0;
    margin: 0;

    h3 {
        color: white;
        text-transform: uppercase;
        padding: 10px;
    }

    .form-inline {
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        border-bottom : 1px solid #e2e2e2 !important;

        .form-control {
            border: none;
        }

        button, select, input {
            font-weight: bold;
            width:100%;
            border-radius:0;
            border-right: 1px solid #e2e2e2 !important;
            font-weight: 200;

            ::placeholder {
                font-weight: 200;
              }
        }

        button {
            width: auto;
            white-space: nowrap;
            padding: 0.375rem 30px;
            background-color: $andcopurple;
            &:hover {
                background-color: $andcopurple;
                color: white;
            }
            
        }

        button.btn-purple {
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                background-color: $andcopurple;
                color: white;
                opacity: 0.8;
                transition: opacity .3s;
            }
        }

        button.btn-green {
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                background-color: $andcogreen;
                color: white;
                opacity: 0.8;
                transition: opacity .3s;
            }
        }

    }
}

@keyframes stretch {
    0% {
      transform: scale(.9);
    }
    100% {
      transform: scale(1);
    }
}