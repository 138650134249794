.card-flex-container {
    display:flex;
    flex-wrap:wrap;
    border:1px dotted grey;
    width:100%;
    .card-flex-item {
        width:calc(33.333% - 10px);
        border:1px dotted grey;
        text-align:center;
        margin:5px;

        .card-flex-header {
            width:100%;
            border:1px dotted grey;
            padding: 10px;
            background-color:#000000;
            color:#FFFFFF;
            font-weight: bold;
            text-align:left;
        }

        .card-flex-table {
            display:flex;
            align-items: center;
            padding: 5px;
            border:1px dotted grey;

            .flex-table-item {
                width:100%;
                border:1px dotted grey;
            }
        }
    }
}