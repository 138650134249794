.legalnotice-page-name {
    position: relative;
    background-color: #7B5099;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}

.legalnotice-top {
    position: relative;
    width: 1050px;
    margin: 0 auto 20px auto;
    text-align: center;

    span {
        font-weight: bold;
    }
}

hr {
    position: relative;
}

.legalnotice-middle {
    position: relative;
    width: 1050px;
    margin: 0 auto 30px auto;
    text-align: center;

    span {
        font-weight: bold;
    }
}