footer {
    color: $white;
    background-color: $black;
    width: 100%;
    padding: 0 10px 0 10px;

    div.footer-dashboard {
        font-size: 14px;
    }

    ul.list-inline {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin:0 0;
        font-size: 12px;
        padding: 50px 0 0 0;

        li.list-inline-item {
            margin-right: 0;
            padding: 5px 20px;
            border-right: 1px solid $white;
        }

        li.list-inline-item:last-child {
            border-right:0;
        }
    }
    a {
        color: $white;
    }
    a:hover {
        color: $grey;
    }

    ul {
        li {
            padding: 1px 5px;
            a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
}
@media all and (max-width: 992px)
{
    footer li.list-inline-item {
        font-size: 14px;
    }
}
