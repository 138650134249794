.forum-form-modal {
    display: none;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 50;

    form {
        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        display: flex;
        align-items: center;
        justify-content: center;

        .forum-modal-container {
            border: 1px solid #bababa;
            width: 800px;
            min-width: 500px;
            background-color: #ffffff;
            padding: 10px;
            text-align: left;
            position: relative;
            overflow: hidden;

            h2 {
                margin-bottom: 20px;
            }

            .forum-modal-close {
                position: absolute;
                top:10px;
                right: 10px;
                padding: 10px;
                cursor: pointer;
                border: 1px solid #bababa;
                color: #bababa;
                transition: border .3s, color .3s;

                &:hover {
                    border: 1px solid #d65757;
                    color: #d65757;
                    transition: border .3s, color .3s;
                }
            }
        }

        input[type="text"], input[type="file"] {
            width: 100%;
        }

        button {
            width: 100%;
        }

        label {
            span {
                color: red;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .forum-form-checkbox {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
}

.forum-messages-container {
    margin: 10px;

    .forum-messages-items {
        position: relative;
        overflow:hidden;
    }

    .forum-message-search-container {

        position:relative;
        margin: 20px 0;

        label {
            font-size: 26px;
            padding: 10px 0 0 0;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            #search_count {
                font-size: 16px;
                font-weight: 100;
                border: 1px solid #bababa;
                color: #bababa;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .message-search-container {
            display: flex;

            input {
                width: 100%;
                border: 1px solid #bababa;
            }

            div {
                width: auto;
                border: 1px solid #bababa;
                border-left:0;
                padding: 10px;
            }

            .forum-message-add {
                margin-left: 10px;
                cursor:pointer;
                color: #333333;
                border: 1px solid #bababa;
                transition: color .3s, backgroud .3s;

                &:hover {
                    color: $andcopurple;
                    border: 1px solid $andcopurple;
                    transition: color .3s, backgroud .3s;

                }
            }
        }

    }

    .forum-message-sizer {
        width: calc(25% - 10px);
    }

    .forum-message {
        
        margin: 5px 0 10px 0;
        background-color: #ffffff;
        width: calc(25% - 10px);
        border: 1px solid #bababa;
        float: left;
        

        .forum-message-options {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;


            .message-option-left {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
            }

            .message-option-right {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 5px;
            }

            .message-option {
                border: 1px solid #bababa;
                padding: 5px;
                cursor: pointer;
                color: #bababa;
                transition: color .3s, border .3s;

                &:hover {
                    color: $andcopurple;
                    border: 1px solid $andcopurple;
                    transition: color .3s, border .3s;
                }
            }

            .message-active-view {
                color: #72b472;
                border: 1px solid #72b472;
            }

            .message-inactive-view {
                color: #d65757;
                border: 1px solid #d65757;
            }
        }

        .message, .form-message {
            border:1px solid transparent;
            margin-top: 3px;
            margin-bottom: 30px;
            transition: border .3s;
            position: relative;
            overflow: hidden;
            

            .title {
                font-size: 26px;
                padding: 10px;
                font-weight: bold;

                input {
                    width: 100%;
                    padding: 5px;
                }
            }

            .text {
                font-size:16px;
                padding: 10px;

                textarea {
                    height: auto;
                    min-height: 250px;
                    overflow-y: auto;
                    word-wrap:break-word
                }
            }

            .file {
                font-size:16px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
            }

            .file-form {
                font-size:16px;
                padding: 10px;

                input {
                    width:100%;
                }
            }

            .checkbox {
                font-size:16px;
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .button {
                padding: 10px;
                button {
                    width: 100%;
                }
            }

            .date {
                font-size: 13px;
                padding: 10px;
                border-top: 1px dashed #bababa;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 5px;
            }

            .message-trash-container {

                position: absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                z-index:60;
                display:none;

                .message-trash {
                    position: absolute;
                    top:0;
                    bottom:0;
                    left:0;
                    right:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    background-color: #ffffff;

                    .message-trash-text {
                        padding: 10px;
                        font-size: 20px;
                    }
                    .message-trash-confirm {
                        padding: 10px;
                        width: 200px;
                        display: block;
                        margin: 5px;
                        text-align:center;
                        cursor:pointer;
                        border: 1px solid #bababa;
                        color:#333333;
                        text-decoration: none;
                        opacity: 1;

                        &:hover {
                            opacity: 0.6;
                            transition: opacity .3s;
                        }
                    }
                    .message-trash-cancel {
                        padding: 10px;
                        width: 200px;
                        display: block;
                        margin: 5px;
                        text-align:center;
                        cursor:pointer;
                        border: 1px solid #bababa;
                        color:#333333;
                        text-decoration: none;
                        opacity: 1;

                        &:hover {
                            opacity: 0.6;
                            transition: opacity .3s;
                        }
                    }
                }
            }
        }

        .form-message {
            display:none;
        }

        &:hover {

            .message, .form-message {
                //border:1px solid #bababa;
                //transition: border .3s;
            }
        }
    }

    .ui-sortable-placeholder {
        border: 1px solid #bababa;
        background: linear-gradient(-45deg, #bababa 12.5%, #fff 12.5%, #fff 37.5%, #bababa 37.5%, #bababa 62.5%, #fff 62.5%, #fff 87.5%, #bababa 87.5%);
        background-size: 100px 100px;
        background-position: 50px 50px;
        visibility: visible !important;
    }

    .forum-message-placeholder {
        border: 1px solid #bababa;
        //width: 100%;
        min-height: 150px;
        background: linear-gradient(-45deg, #bababa 12.5%, #fff 12.5%, #fff 37.5%, #bababa 37.5%, #bababa 62.5%, #fff 62.5%, #fff 87.5%, #bababa 87.5%);
        background-size: 100px 100px;
        background-position: 50px 50px;
    }
}