.email-edit-container {
    display: flex;
    //margin-left: 60px;

    .email-edit-return {
        display: block;
    }

    .email-edit-form-container {
        border: 1px solid #BABABA;
        width: 40%;
        margin: 5px;
        padding: 10px;
    }

    .email-edit-view-container {
        border:1px solid #BABABA;
        width: 60%;
        margin: 5px;
    }
}

.email-dynamic-word-container {
    position:relative;
    border:1px solid #cccccc;
    margin-bottom: 20px; 

    .email-dynamic-word-notice {
        padding: 10px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;
        display: flex;
        align-items: center;

        .text {
            width: 100%;
        }
        .icon {
            padding: 0 10px;
            transform: rotate(0deg);
            transition: transform .3s;
        }

        .icon-open {
            transform: rotate(180deg);
            transition: transform .3s;
        }

        &:hover {
            opacity: 0.7;
            transition: opacity .3s;
        }
    }

    .email-dynamic-word-notice-info {
        position: relative;
        display:none;
        border-top: 1px dashed #BABABA;

        .email-dynamic-word-title {
            font-weight: bold;
            padding: 10px 10px 0 10px;
        }

        .email-dynamic-word-items {
            /*border:1px solid #BABABA;*/
            display:flex;
            flex-wrap:wrap;
            margin-bottom:5px;
            font-size: 12px;
            padding: 10px;

            .word-item {
                border:1px solid #BABABA;
                padding:0;
                margin:5px 5px 5px 0;
                display: flex;
                background-color: #FFFFFF;
                width: calc(25% - 10px);
                position: relative;
                overflow: hidden;

                input {
                    border: 0;
                    width: 100%;
                    padding: 3px 5px;
                    outline: none;
                    display: block;
                }

                button {
                    border: 0;
                    border-left: 1px solid #BABABA;
                    outline: none;
                    width:auto;
                }
            }
        }
    }
}