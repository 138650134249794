.nav-choice-lang {
    width: auto;
    padding: 5px;
    position: relative;
    overflow: visible;
    max-width: 45px;

    ul.lang-items-list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        text-align: center;
        display: none;
        z-index: 88888;
        width: 100%;
        /*background-color: $white;
        border: 1px solid $greyligth;*/
        li {
            padding: 0;
            margin: 0;
        }
    }

    .lang-item {
        display: block;
        color: $grey;
        margin: 0 0 0 0;
        padding: 5px;
        text-align: center;
        opacity: 1;
        transition: opacity .3s;

        img {
            width: 25px;
            padding: 0;
            margin: 0;
            vertical-align: middle;
        }
        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }
}