.modal-content {
    border-radius: 0;

    .modal-header {
        padding: 5px 10px;
    }

    .modal-body {
        padding: 0;
    }
}

#confirmation_redirection_to_user_modal {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 9998;
    display: none;

    .confirmation-modal-container {

        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.9);

        .modal-redirect {
            border: 1px solid #e9ecef;
            background-color: $white;

            .content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                overflow: hidden;

                .label {
                    padding: 30px 10px;
                    font-size: 20px;
                }

                .redirection-to-user-confirm {
                    cursor: pointer;
                    padding: 10px;
                    border: 1px solid #e9ecef;
                    width: calc(100% - 10px);
                    text-align: center;
                }

                .redirection-to-user-cancel {

                    cursor: pointer;
                    padding: 10px;
                    border: 1px solid #333333;
                    color: #ffffff;
                    background-color: #333333;
                    margin: 5px 0;
                    width: calc(100% - 10px);
                    text-align: center;
                }
            }
        }

    }

}


#response_form_confirm_modal {

    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:888;
    overflow:hidden;
    display: none;

    .response-form-confirm-modal {
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(255, 255, 255, 0.8);

        .confirm-modal-content {
            border: 1px solid #333333;
            background-color:#ffffff;
            padding: 20px;

            h2 {
                margin-bottom: 30px;
                text-align: center;
            }

            .confirm-modal-content-btn {
                display:flex;
                align-items:center;
                gap: 10px;
                padding: 10px;

                .content-btn {

                    width: 100%;
                    border: 1px solid #333333;
                    padding: 10px;
                    text-align:center;
                    cursor: pointer;
                    color: #ffffff;
                    background-color: #333333;
                    width: 200px;
                    transition: color .3s, background .3s;

                    &:hover {
                        color: #333333;
                        background-color: #ffffff;
                        transition: color .3s, background .3s;
                    }
                }
            }
        }
    }
}

