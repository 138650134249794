.offer-page-name {
    position: relative;
    background-color: #7B5099;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}

.offer-middle {
    position: relative;
    text-align: center;
    h2 {
        color: #515151;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        margin-right: 1px;
        text-shadow: 4px 2px #cecece;
        text-transform: uppercase;
        padding-top: 100px;
    }

    p {
        font-size: 24px;
        line-height: 40px;
        margin-top: 30px;
        color: #6b6b6b;
    }

    .mac-middle-slider {
    
        height: auto;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        background-color: #FFFFFF;
        z-index: 2;
        height: auto;
        margin: auto;
    
        .mac-middle-slider-bg {
            z-index: 1;
            width: 60%;
            height: auto;
            position: relative;
            margin: auto;
    
            img {
                width: 100%;
            }
        }
    
        .mac-middle-slider-content {
            background-color: #FFFFFF;
            width: 70%;
            margin: auto;
            height: 85%;
            border-radius: 10px 10px 0 0;
            color: white;
            z-index: 2;
            position: absolute;
            left: calc(50% - 35%);
            top: 5%;
            overflow: hidden;
    
            .slick-slide  {
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
            }
        }
    
        img {
            width: 100%;
        }
    
        .mac-points {
            position: absolute;
            top: 0;
            left:0;
            right: 0;
            bottom:0;
            margin: 0;
            padding: 0;
            list-style: none;
            overflow: visible;
    
            .mac-point {
    
                position: absolute;
                width: 250px;
    
                .mac-point-ball {
    
                    position: relative;
                    background-color: rgba(151, 190, 30, 0.9);
                    color: #FFFFFF;
                    width: 30px;
                    height: 30px;
                    margin: 10px auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 13px;
                    cursor: pointer;
                    box-shadow: 0 0 0 0 rgba(169, 172, 161, 0.9);
                    animation: pulse-grey 3s infinite;
                    z-index: 4;

                }
    
                .mac-point-content {
    
                    background-color: #FFFFFF;
                    position: relative;
                    border-radius: 4px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                    padding: 10px;
                    display: none;
                    text-align: left;
                    z-index: 5;
    
                    .point-content-title {
                        position: relative;
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                        padding: 5px 0;
    
                    }
    
                    .point-content-text {
                        position: relative;
                        color: #333333;
                        font-size: 14px;
                    }
    
                    .point-content-more {
                        position: relative;
                        padding: 20px 0 0 0;
                        color: rgb(91, 37, 128);
                        text-align: right;
                        font-size: 13px;
                        font-weight: bold;
                        display: block;
                        text-decoration: inherit !important;
                    }
                }
    
            }
    
            .mac-point-1 {
                top: 50%;
                left: 1%;
            }
    
            .mac-point-2 {
                top: 50%;
                left: 25%;
            }
    
            .mac-point-3 {
                top: 50%;
                left: 75%;
            }
    
            .mac-point-4 {
                top: 60%;
                left: 75%;
            }
        }
    }
    
    .mac-middle-slider-visible {
        overflow: visible;
        border-bottom: 0;
        margin-bottom: 20px;
        padding-bottom: 50px;
    
        .mac-middle-slider-content {
            overflow: visible;
        }
    }
}

.offer-middle-bottom {
    position: relative;
    margin-bottom: 30px;
}

@keyframes pulse-grey {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(136, 136, 136, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(255, 82, 82, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}