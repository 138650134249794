.ball {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $grey;
    margin: auto;
    line-height:0;
    font-size:0;
}

.breadcrumb-container {
    position: relative;
    font-size: 12px;
    padding: 5px 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .breadcrumb-link {
        padding: 3px;
    }
}

.hidden {
    display: none;
}

.ball-green {
    background-color: #42c542;
}

.ball-red {
    background-color: #e43e3e;
}

.ql-snow .ql-picker.ql-size {

    .ql-picker-item[data-value="8px"]::before {
        content: '8px';
        font-size: 8px !important;
    }

    .ql-picker-item[data-value="9px"]::before {
        content: '9px';
        font-size: 9px !important;
    }

    .ql-picker-item[data-value="10px"]::before {
        content: '10px';
        font-size: 10px !important;
    }

    .ql-picker-item[data-value="11px"]::before {
        content: '11px';
        font-size: 11px !important;
    }

    .ql-picker-item[data-value="12px"]::before {
        content: '12px';
        font-size: 12px !important;
    }

    .ql-picker-item[data-value="13px"]::before {
        content: '13px';
        font-size: 13px !important;
    }

    .ql-picker-item[data-value="14px"]::before {
        content: '14px';
        font-size: 14px !important;
    }

    .ql-picker-item[data-value="16px"]::before {
        content: '16px';
        font-size: 16px !important;
    }

    .ql-picker-item[data-value="18px"]::before {
        content: '18px';
        font-size: 18px !important;
    }

    .ql-picker-item[data-value="20px"]::before {
        content: '20px';
        font-size: 20px !important;
    }

    .ql-picker-item[data-value="22px"]::before {
        content: '22px';
        font-size: 22px !important;
    }

    .ql-picker-item[data-value="24px"]::before {
        content: '24px';
        font-size: 24px !important;
    }

    .ql-picker-item[data-value="26px"]::before {
        content: '26px';
        font-size: 26px !important;
    }

    .ql-picker-item[data-value="28px"]::before {
        content: '28px';
        font-size: 28px !important;
    }

    .ql-picker-item[data-value="30px"]::before {
        content: '30px';
        font-size: 30px !important;
    }

    .ql-picker-item[data-value="34px"]::before {
        content: '34px';
        font-size: 34px !important;
    }

    .ql-picker-item[data-value="38px"]::before {
        content: '38px';
        font-size: 38px !important;
    }

    .ql-picker-item[data-value="42px"]::before {
        content: '42px';
        font-size: 42px !important;
    }
}

.ql-snow{
    .ql-picker{
        &.ql-size{
            .ql-picker-label,
            .ql-picker-item{
                &::before{
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.col-form-label {
    padding-top: 0;
}

.container-admin {
    margin: 70px 10px 10px 80px;
    overflow-x:auto;
}

.template-main {
    position: relative;
    margin:10px;
}

.tooltip {
    position: fixed;
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
    z-index: 9000;
    top: 0;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    display: inline;
    -webkit-box-shadow: -1px 7px 12px 2px rgba(0,0,0,0.33); 
    box-shadow: -1px 7px 12px 2px rgba(0,0,0,0.33);
    //border-radius: 4px;
    opacity: 1;
    border-left: 3px solid #333333;
}

.custom-form-flex {
    display: flex;
    //align-items: center;

    .custom-flex-item {
        margin: 5px; 
        width: 100%;
        padding: 5px;

        .flex-item {
            display: flex;
            align-items:center;

            .item {
                width:100%;
                padding: 0 2px; 
            }

            .item-index-at {
                width: 200px;
            }
        }
    }
}

.section-nav-fixed, 
.lot-nav-fixed {
    position: fixed;
    top: 90px;
    left:0;
    right:0;
    z-index: 700;
    border-bottom: 1px solid #e9ecef;
}

.btn-return-container {
    display:flex;
    justify-content:flex-end;
    position:relative;
    width: 100%;
    padding: 10px 0;

    .btn-return {
        display: block;
        padding: 10px;
        border: 1px solid #dee2e6;
        color: inherit;
        text-decoration: none;
    }
}

.bg-info {
    padding: 20px 10px;
    color: #ffffff;
}

.setDepartmentsByRegion {
    border: 1px solid #e9ecef;
    background-color: #ffffff;
    margin: 5px;
    &:hover {
        opacity: 0.8;
    }

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}


small {
    font-size: 11px;
    font-weight: 100;
    padding: 0;
}

.global-loader {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index:999999999;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}

.global-loader-hidden {
    display: none;
    z-index: -1;
}

.modal-message {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 90;
    display: none;

    .modal-message-container {
        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        display:flex;
        align-items: center;
        justify-content: center;
        overflow:hidden;
        background-color: rgba(255, 255, 255, 0.8);

        .modal-message-main {
            width: 70%;
            height: 70vh;
            max-width: 800px;
            max-height: 800px;
            border: 1px solid #bababa;
            background-color: #ffffff;
            position: relative;
            overflow: visible;
            //border-radius: 3px 0 3px 3px;
    
            .modal-message-close {
                position: absolute;
                padding: 5px;
                border: 1px solid #bababa;
                background-color: #ffffff;
                right: -28px;
                top: 0;
                color: #bababa;
                cursor:pointer;
                transition: color .3s, background .3s;
    
                &:hover {
                    border: 1px solid #da4d4d;
                    color: #da4d4d;
                    transition: color .3s, background .3s;
                }
            }
    
            .modal-message-header {
    
                border-bottom: 1px solid #bababa;
                display: flex;
                align-items: center;
                justify-content: flex-start;
    
                .title {
                    padding: 10px;
                    font-size: 20px;
                    color: #bababa;
                    width: 100%;
    
                    .dynamic-text {
                        font-size: 12px;
                    }
                }

                .search {
                    width: 40px;
                    color: #bababa;
                }
    
            }
    
            .modal-message-content {
                overflow: hidden;

                .modal-messages-list {
                    position: absolute;
                    top: 51px;
                    bottom:106px;
                    left: 0;
                    right: 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-bottom: 50px;
                    //border: 1px dotted red;

                    #modal_messages_list_empty {
                        /*position: relative;
                        padding: 50px 10px;
                        display: none;
                        text-align: center;*/
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    #list_messages {

                        .message-load-more {
                            position: relative;
                            padding: 10px;
                            text-align: center;
                            font-size: 18px;

                            i {
                                opacity: 1;
                                transition: opacity .3s;
                                cursor:pointer;

                                &:hover {
                                    opacity: 0.6;
                                    transition: opacity .3s;
                                }
                            }
                        }

                        .message-item-container {
                            position: relative;
                        }

                        .message-item {

                            border:1px solid #bababa;
                            padding:0;
                            margin: 10px;

                            .message-item-society {
                                padding: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                padding: 10px;
                                gap: 5px;
                                border-bottom: 1px solid #bababa;
                                font-weight:bold;
                            }

                            .message-item-text {
                                padding: 10px;
                            }

                            .message-item-file {
                                font-size: 11px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                padding: 10px;
                                gap: 5px;
                            }

                            .message-item-date {
                                border-top : 1px dashed #bababa;
                                font-size: 11px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                padding: 10px;
                                gap: 5px;

                                .item-read {
                                    width: auto;
                                    padding: 0 5px;
                                }

                                .is-read {
                                    color: #63b163;
                                }

                                .is-not-read {
                                    color: #eb5c5c;
                                }

                                .item-date {
                                    text-align: right;
                                    width: 100%;
                                }
                            }
                        }

                        .message-item-1 {
                            background-color: rgba(222, 226, 230, 0.2);
                            margin-right: 30px;
                            text-align:left;

                        }
                        .message-item-2 {
                            background-color: rgba(245, 192, 242, 0.2);
                            margin-left: 30px;
                            text-align: right;
                        }
                    }

                    .modal-messages-list-loader {
                        position: absolute;
                        top: 0;
                        bottom:0;
                        left: 0;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        i {
                            color: #bababa;
                            font-size: 32px;
                            animation-duration: .8s;
                            animation-name: flash;
                            animation-iteration-count: infinite;
                            transition: none;
                        }

                        p {
                            margin-top: 10px;
                            font-size: 10px;
                        }
                    }
                    .modal-messages-list-loader-hidden {
                        display:none;
                    }
                }

                .modal-width-subcontractors {
                    //margin-right:200px;
                    right: 200px;
                }
    
                .modal-message-form {
                    border-top: 1px solid #bababa;;
                    position: absolute;
                    bottom: 40px;
                    left:0;
                    right:0;
                    display: flex;
                    justify-content: center;
    
                    label {
                        border-right: 1px solid #bababa;
                        position: relative;
                        overflow:hidden;
                        width:auto;
                        min-width: 100px;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        color: #bababa;
    
                        input {
                            opacity:0;
                            visibility: hidden;
                            position: absolute;
                        }

                        .messenger-message-file-type,
                        .messenger-message-file-size,
                        .messenger-message-file-trash {
                            font-size: 10px;
                            text-align: center;
                        }

                        .messenger-message-file-trash {
                            cursor:pointer;
                            opacity:1;
                            transition: opacity .3s;
                            &:hover {
                                opacity: 0.8;
                                transition: opacity .3s;
                            }
                        }
                    }

                    .messenger-message-as-file {
                        //color: $andcopurple;
                    }
    
                    textarea {
                        width: 100%;
                        box-sizing: border-box;
                        max-width: 100%;
                        min-width: inherit;
                        border: 0;
                        border-radius: 0;
                        border: none;
                        overflow: auto;
                        outline: none;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        resize: none;
                    }
    
                    button {
                        width: 100px;
                        border: 0;
                        border-left: 1px solid #bababa;
                        color: #bababa;
                    }
                }

                #list_subcontractors {

                    position: absolute;
                    top: 51px;
                    bottom:106px;
                    right: 0;
                    width: 200px;
                    border-left: 1px solid #bababa;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    display:none;
                    overflow: auto;

                    .list-subcontractors-search-container {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        border-bottom: 1px solid #bababa;
                        padding: 0;
                        margin-bottom: 5px;

                        input#messenger_search_subcontractors {
                            width:100%;
                            padding:5px;
                            border:0;
                            border-right: 1px solid #bababa;
                            font-size: 12px;
                            border-radius: 0;
                        }

                        .search-icon {
                            padding: 0 5px;
                            color: #bababa;
                            font-size: 11px;
                        }
                    }
    
                    .subcontractor-item {
                        border: 1px solid #bababa;
                        background-color: #ffffff;
                        font-size: 10px;
                        text-transform: uppercase;
                        cursor: pointer;
                        margin: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 3px;
                        transition: border .3s, background .3s, color .3s;
                        position: relative;
                        overflow: visible;

                        i, span {
                            width: auto;
                            padding: 0 3px;
                        }

                        div.item-society-name {
                            width:100%;
                            font-size: 11px;
                        }

                        div.item-unread {
                            border: 1px solid green;
                            font-size: 9px;
                            display:flex;
                            position: absolute;
                            top: -2px;
                            right: -2px;
                            bottom: -2px;
                            color: green;
                            background-color: #ffffff;
                            align-items: center;
                            justify-content: center;
                            width: auto;
                        }

                        &:hover {
                            border:1px solid $andcopurple;
                            background-color: $andcopurple;
                            color: #ffffff;
                            transition: border .3s, background .3s, color .3s;
                        }
                    }

                    .subcontractor-item-active {
                        border:1px solid $andcopurple;
                        background-color: $andcopurple;
                        color: #ffffff;
                        transition: border .3s, background .3s, color .3s;
                    }
                }
            }
    
            .modal-message-footer {
    
                border-top: 1px solid #bababa;
                background-color: #ffffff;
                z-index:10;
                position: absolute;
                bottom: 0;
                left:0;
                right:0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 40px;
    
                .date-now {
                    padding: 10px;
                    font-size: 11px;
                    color: #bababa;
                    font-style: italic;
                }
            }
        }
    }

    
}

@keyframes flash {
    0%   { opacity:1; }
    40%   {opacity:0; }
    100% { opacity:1; }
}

.alert-error {
    background-color: rgba(228, 62, 62, 0.5);
    border-radius: 0;
    color: #ffffff;
}