.faq-search-container {
    position: relative;
    border:1px solid #b8b8b8;
    width:100%;
    margin: 10px 25px;
    min-height:30px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
        border: 0;
        width: 100%;
        padding: 10px;
        background: $white;
        text-align:center;
    }

    button {
        width: 40px;
        padding: 10px;
        border:0;
        background: $white;
        border-left: 1px solid #b8b8b8;
        display: none;
    }
}

.faq-entity-name {
    position: relative;
    margin: 0 5px;
    padding: 5px 10px 0 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: lowercase;

    border-bottom: 1px solid #bebebe;

    &:first-letter {
        text-transform: uppercase;
    }
}

.faq-empty-data {
    position: relative;
    padding: 10px;
}
.faq-cols-container {
    position: relative;
    min-height:200px;
    width: 100%;
    margin: 10px;
    display: flex;
    padding: 0;
    justify-content:center;
    flex-wrap:wrap;
}
.faq-col {
    width:calc(50% - 20px);
    min-height: 200px;
    margin: 10px;
    min-width:350px;
}
.faq-view-answer-btn {
    width:50px;
    padding:10px;display:flex;
    align-items:center;
    justify-content:center;
    font-size: 22px;cursor:pointer;
    background-color:$andcogreen;
    color:$white;
    border:1px solid $andcogreen;
    transition: background .3s, border .3s;
    i {
        transform: rotate(0deg);
        transition: transform .3s; 
    }
}
.faq-view-answer-btn-open {
    border:1px solid $andcopurple;
    background-color: $andcopurple;
    transition: background .3s, border .3s;
    i {
        transform: rotate(45deg);
        transition: transform .3s;
    }
}
.faq-item {
    margin:10px;
}
.faq-item-question {
    display:flex;
}
.faq-item-question-text {
    border:1px solid #b8b8b8;
    width:calc(100% - 50px);
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding: 5px 10px;
    font-weight:bold;
}
.faq-answer {
    border:1px solid #b8b8b8;
    padding:25px;
    color:#b8b8b8;
    margin: 5px 0;
    font-size: 14px;
    display:none;
}
.faq-col-title {
    position:relative;
    font-size: 32px;
    font-weight:bold;
    padding: 10px;
}
.faq-col-subtitle {
    font-size: 13px;
    padding:0 10px;
    color:#b8b8b8;
}