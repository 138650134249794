#sidebar {
    border-right: 1px solid $grey;
    position: fixed;
    top: 55px;
    left: 0;
    bottom: 0;
    background-color: $white;
    width: 65px;
    z-index: 666;

    li.list-group-item {
        padding: 0.30rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        a.btn {
            padding: 0.30rem;
            display:block;
        }
    }
    li.list-group-item:not(:last-child),
    li.dropdown-item:not(:last-child) {
        border-bottom: 1px solid $grey;
    }
    a:first-letter {
        text-transform: uppercase;
    }

    .btn-active {
        color: #BABABA !important;
        span > i {
            color: #BABABA !important;
        }
    }
}
/*#sidebar ~ .flex-row,
#sidebar ~ .row,
#sidebar ~ form > .row {
    margin-left: $sidebarwidth !important;
}*/
@media (max-width: 992px) {
    #sidebar {
        top: 40px;
    }
}
