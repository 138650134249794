.altares-container {
    position: relative;
    //padding:20px 100px;

    .altares-nav {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        margin: 10px 0;

        a {
            display: block;
            padding: 10px;
            border: 1px solid #333333;
            color: #333333;
            text-transform: uppercase;
        }
    }

    .altares-subscription-count {
        position: relative;
    }

    .altares-subscription-search {
        position: relative;
        display: flex;

        input {
            width: 100%;
            border: 1px solid #333333;
        }

        button {
            border: 1px solid #333333;
            border-left: 0;
            width: 100px;
        }
    }

    .altares-subscription-list {

        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        font-size: 13px;

        .altares-subscription-list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #333333;
            margin: 10px 0;

            .altares-checkbox {
                width: auto;
                border-right: 1px solid #333333;
                text-align:center;
                padding: 10px;
            }

            .altares-name {
                border-right: 1px solid #333333;
                padding: 10px;
                width: 400px;
                white-space: nowrap;
                font-size: 13px;
            }

            .altares-siret {
                border-right: 1px solid #333333;
                padding: 10px;
                width: 200px;
                text-align: center;
            }

            .altares-date {
                padding: 10px;
                width: 200px;
                white-space: nowrap;
                font-size: 13px;
                text-align: center;
            }
        }
    }

    .test-connexion-success {
        font-size:22px;
        position:relative;
        border:1px solid green;
        padding: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:green;
        margin:10px 0;
    }

    .test-connexion-error {
        font-size:22px;
        position:relative;
        border:1px dashed red;
        padding: 10px;
        display:flex;
        justify-content:center;
        align-items:center;
        color:red;
        margin:5px 0;
    }

    .block-container {
        position:relative;
        border: 1px solid #333333;
        padding: 0;
        margin: 10px 0;

        form {
            .in-form-block {
                display:flex;
                align-items:center;
                padding:10px;

                .wrapper {
                    padding: 10px;
                    margin: 0 5px 0 0;
                    width:100%;

                    select {
                        height: 300px;
                    }
                }
            }
        }
    }

    .col-container {
        display:flex;
        margin:2px;

        .col-file {
            width:100%;
            margin:3px; 
            

            .col-header {
                font-size: 18px;
                font-weight:bold;
                border: 1px solid #BABABA;
                display:flex;

                .number {
                    padding: 5px 10px;
                    border-right: 1px solid #BABABA;
                    font-size: 14px;
                    font-weight: 100;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text {
                    padding: 5px;
                }
            }

            .col-content {
                max-height: 600px;
                overflow: auto;
                border:1px solid #BABABA;
                border-top:0;
                padding-bottom: 10px;
            }

            .file-container {
                margin: 5px 10px;

                a {
                    display:flex;
                    align-items:center;

                    i {
                        width: 25px;
                    }

                    div {
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    form {
        button {
            width:400px;
            margin: 0 0;
        }
    }
}

.repport-container {
    position: relative;
    padding:20px 30px;

    .repport-filename {
        padding:10px 0;
        //border:1px solid #BABABA;
        margin: 5px;
    }

    .repport-col-container {
        display:flex;
        flex-direction:column; 

        .repport-row {

            width:calc(100% - 10px);
            margin:5px;
            font-size: 13px;
            overflow:auto;
            max-width:100%;
            //max-height:80vh;

            table {
                thead {

                    tr {
                        td {
                            width:100%;
                            border:1px solid #BABABA;
                            padding: 10px 5px;
                            white-space:nowrap;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            width:100%;
                            border:1px solid #BABABA;
                            padding: 10px 5px;
                            white-space:nowrap;
                        }
                    }
                }
                
            }
        }
    }
}