/*.table-options {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .item-options-search {
        padding: 0;
        margin: 0 0 0 3px;
        text-align: center;
        border-bottom: 0;

        form {

            display: flex;

            .table-search {
                width: 200px;
                border: 1px solid $grey;
                font-size: 12px;
                border-bottom: 0;
            }

            button.item-options {
                width: 30px;
                border-left: 0;
                border-bottom: 0;
                font-size: 11px;
                text-align: center;
                color: $grey;
                transition: color .3s;
                margin: 0;

                &:hover {
                    color: $black;
                    transition: color .3s;
                }
            }
        }
    }

    .item-options {
        border: 1px solid $grey;
        padding: 5px;
        margin: 0 0 0 3px;
        text-align: center;
        background-color: $white;
        font-size: 12px;
        border-bottom: 0;
    }

    .expand-option {
        i {
            transform: rotate(90deg);
            transition: transform .3s;
        }
    }

    .expand-option-active {
        i {
            transform: rotate(0deg);
            transition: transform .3s;
        }
    }
}*/

.table-zone-small {
    font-size: 11px;
}

.table-options {
    display:flex;
    align-items: center;
    justify-content:flex-end;
    border:1px solid #dee2e6;
    border-bottom:0;

    .option-item {
        padding: 10px 15px;
        display:block;
    }

    .option-item-full {
        width:100%;
    }

    .table-search-form {
        display: flex;
    }

    input.table-search-field {
        width: 100%;
        padding: 10px;
        font-size: 18px;
        border: 0;
        outline-width: 0;
        border-left: 1px solid $grey;
    }

    button.table-search-submit {
        border: 0;
        background-color: $black;
        color: $white;
        opacity: 1;
        transition: opacity .3s;
        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }

    .option-item-multiple-action {
        width: auto;
        padding: 0;

        .table-form-multiple-action {

            display: flex;

            select {
                width: auto;
                border: 0;
                background-color: $white;
                padding: 10px;
                font-size: 18px;
            }

            button.table-options-multiple-action-btn {
                border: 0;
                background-color: $black;
                color: $white;
                opacity: 1;
                transition: opacity .3s;
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }

        }

    }

    .expand-option {
        i {
            transform: rotate(0deg);
            transition: transform .3s;
        }
    }

    .expand-option-active {
        i {
            transform: rotate(90deg);
            transition: transform .3s;
        }
    }
}

.table-expanded {
    position: relative;
    overflow-x: auto;

    .table {
        min-width: 100%;
        width: auto;
    }

    th, td {
        white-space: nowrap;
    }

    td {
        .flex-wrap {
            flex-wrap: nowrap;
        }
    }
}

.td-span-hidden {
    display:none;
}

.ui-resizable-e {
    z-index: 999;
    width: 5px;
    border-left: 1px solid transparent;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    &:hover {
        cursor: crosshair;
    }
}

.table-container {
    position: relative;
    //max-width: 100%;
    //overflow: auto;
}

table {
    display: table !important;
    border:1px solid #BABABA;
    font-size: 15px;
    width:100%;

    .row-response-lot {
        padding:5px;
        border:1px solid #bababa;
        margin: 3px;
        font-size:11px;
    }

    
    thead {
        tr {
            th {
                padding: 10px 5px;
                //min-width: 250px;
                a {
                    display: flex;
                    text-align: left;
                    * {
                        width: 100%;
                    }
                    span {
                        float: none;
                        order: 2;
                        margin: 0 0 0 10px;
                    }
                }
            } 
        }
    }
    th, td {
        //white-space: nowrap;
    }

    th.center, td.center {
        text-align: center;
        vertical-align: middle;

        a {
            color: inherit;
            text-decoration: none;
        }

        i {
            margin: 0 10px;
        }
    }

    th.auto, td.auto {
        width: auto;
        min-width: auto;
        padding: 5px;
        vertical-align: middle;

        a {
            color: inherit;
            text-decoration: none;
        }

        i {
            margin: 0 10px;
        }
    }

    .table-zone-item {
        border:1px solid #e2e2e2;
        margin: 2px;
        padding:5px;
        background-color:white;
        font-size: 12px;
    }

    button {
        border: 1px solid #BABABA;
        background-color: #ffffff;
    }
}

.pagination {

    font-size: 13px;

    .page-item.disabled .page-link {
        color: #6c757d;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

    .page-item:first-child .page-link {
        margin-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }


    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: #68696b;
        border-color: #68696b;
    }

    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #333333;
        background-color: #fff;
        border: 1px solid #dee2e6;
    }

}

.jquery-pagination {
    list-style-type: none;
    margin: 10px 0 0 0 0;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li.jquery-pagination-page {
        border: 1px solid #bababa;
        padding: 5px;
        margin: 5px;
        cursor:pointer;
        opacity: 1;
        transition: opacity .3s;
        font-size: 12px;

        &:hover {
            opacity: 0.6;
            transition: opacity .3s;
        }
    }

    li.jquery-pagination-page-current {
        border: 1px solid #333333;
        color: white;
        background-color: #333333;
    }
}