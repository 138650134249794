.login-page-name {
    position: relative;
    background-color: #97be1e;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 30px;
    padding: 20px;
    font-weight: bold;
}

.login-account-page-name {
    position: relative;
    background-color: #5b2580;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 30px;
    padding: 20px;
    font-weight: bold;
}

.create-account-main {
    position: relative;

    .form-account-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 30px;
        flex-direction: column;
    }
    .form-account-block-flex {
        width: 50%;
        min-width: 955px;
        padding: 10px;
    }
    .form-account-block {
        border: 1px solid #BABABA;
        padding: 0.5rem;
        position: relative;

        h2 {
           text-align: center ;
        }

        .form-account-step-number {
            position: absolute;
            top: -10px;
            left: -10px;
            padding: 10px;
            border: 1px solid purple;
            color: purple;
            font-size: 13px;
            font-weight: bold;
            background-color: white;
        }
    }

    .form-account-ocr-container {
        text-align:center;

        label {
            display:block;
            cursor:pointer;
            border: 1px solid purple;
            padding: 10px;
            margin: 10px;
            background: repeating-linear-gradient(60deg, white, white 35px, #f3ddfa 35px, #f3ddfa 70px);
            min-height: 100%;

            .form-account-ocr-filename {
                font-size: 18px;
                font-weight: bold;
                color: purple;
            }

            input[type="file"] {
                position:absolute;
                opacity:0;
                z-index:-1;
                visibility:0;
            }

            button {
                background-color: purple;
                border: 1px solid purple;
            }
        }

        a {
            display:block;
            border-top: 1px solid #BABABA;
            margin-top:20px;
            padding: 10px;
        }
    }

    .form-account-file-container {
        text-align:center;

        label {
            display:block;
            cursor:pointer;
            border: 1px solid purple;
            padding: 40px 10px;
            margin: 10px;
            background: repeating-linear-gradient(60deg, white, white 35px, #f3ddfa 35px, #f3ddfa 70px);
            min-height: 100%;

            .form-account-file-filename {
                font-size: 18px;
                font-weight: bold;
                color: purple;
            }

            input[type="file"] {
                position:absolute;
                opacity:0;
                z-index:-1;
                visibility:0;
            }
        }
    }

    .form-account-file-container-grey {
        text-align:center;

        label {
            cursor: pointer;
            border: 1px solid #919191;
            background: repeating-linear-gradient(60deg, white, white 35px, #e9e9e9 35px, #e9e9e9 70px);

            .form-account-file-filename {
                font-size: 18px;
                font-weight: bold;
                color: #919191;
            }

            input[type="file"] {
                position:absolute;
                opacity:0;
                z-index:-1;
                visibility:0;
            }
        }
    }

    .form-account-field {
        margin: 3px;
        display: flex;
        align-items: center;
        width:100%;

        label {
            display: block;
            width: 40%;
            text-align: left;
            font-weight: bold;

        }

        button {
            display: block;
            width: 100%;
            margin: 20px 0;
        }
    }

    .form-account-fields-flex {
        display: flex;
        .form-account-field {
            display: block;
            width: 100%;

            label {
                width: 100%;
            }

            input {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }

    .form-account-button {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        button {
            display: block;
            width: 100%;
            margin: 0;
            border-radius: 0;
            transition: background .3s;
            background-color: #fadbfa;
            border: 1px solid purple;
            padding: 20px;
            &:hover {
                background-color: #333333;
                border: 1px solid #333333;
                transition: background .3s;
            }

            &:disabled {
                opacity: 1;
                background-color: #BABABA;
                border: 1px solid #BABABA;
            }
        }
    }
}
