.search-page-container {

    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 888;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .search-page-close {
        position: fixed;
        top:65px;
        right:30px;
        font-size: 22px;
        border: 1px solid #333333;
        background-color: #333333;
        color: #ffffff;
        cursor: pointer;
        padding: 10px;
        border-radius: 4px;
    }

    .search-page-items {
        position: relative;
        padding: 10px;
        background-color: #ffffff;
        width: 800px;
        margin: auto;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        flex-wrap:wrap;

        a {
            display: block;
            margin: 5px;
            border: 1px solid #6d6d6d;
            border-radius: 0;
            padding: 10px 30px;
            font-size: 14px;
            font-weight: 200;
            transition: border .3s, color .3s;
            min-width: 200px;

            &:hover {
                border: 1px solid $andcopurple;
                color: $andcopurple;
                transition: border .3s, color .3s;
            }
        }
    }
}

.search-page-form {
    display:flex;
    border:1px solid #BABABA;

    input[type=text] {
        margin:0;
        border:0;
        background:white;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 200;

        &::placeholder {
            font-size: 12px;
            font-weight: 200;
            font-style: italic;
            color: #333333;
        }
    }

    button[type=submit] {
        margin:0;border:0;background:white;padding: 5px 10px;
    }
}