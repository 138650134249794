#messenger-js {
    background-color: $white;
    bottom: 50px;
    height: auto;
    position: fixed;
    right: 20px;
    width: 600px;
    z-index: 1040;
    border: 1px solid $andcopurple;
    border-radius:0;

    #messenger-header {
        border-bottom: 1px solid $andcopurple;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            margin: 5px;
            color: $andcopurple;
        }

        #messenger-title {
            width: calc(100% - 60px);
            font-size: 14px;
        }

        #messenger-close {
            width: 30px;
            padding: 5px;
            margin: 5px;
            border: 1px solid $andcopurple;
            background-color: $andcopurple;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: #ffffff;
                margin: 0;
                padding:0;
                font-size: 14px;
            }
        }
    }

    #messenger-body {
        min-height: 200px;
        max-height: 400px;
        overflow-y: scroll;
        background-color: #e9e9e9;
        
        .card {
            margin: 5px;
            background-color: #ffffff;
            border: 1px solid $andcopurple;
        }

        .is-reverse {
            background-color: #ffffff;
            border: 1px solid $andcogreen;

        }

        .card-body {
            padding: 10px;
            
        }

        .card-title {
            font-weight: bold;
        }

        .card .card-subtitle {
            color: $grey;
        }

        .card.bg-primary .card-subtitle {
            color: $white;
        }
    }

    #messenger-footer {
        border-top: 1px solid $andcopurple;

        #form-messenger-message {
            position: relative;
            overflow: hidden;
            display: flex;

            textarea {
                min-width: calc(100% - 40px);
                max-width: calc(100% - 40px);
                box-sizing: border-box;
                border:0;
            }

            button {
                width: 40px;
                border:0;
                border-left: 1px solid $andcopurple;
                background-color: $andcopurple;
                color: #ffffff;
            }
        }
    }
}
