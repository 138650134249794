.development-page-name {
    position: relative;
    background-color: #7B5099;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}

.development-top {
    text-align:center;
    margin: -60px auto 10px auto;
    position: relative;
    padding: 10px;
    background-image: url(/public/img/pneu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    h2 {
        color: #515151;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        margin-right: 1px;
        text-shadow: 4px 2px #cecece;
        text-transform: uppercase;
        border-bottom: 2px dotted #ffffff;
    }
}

.development-top-text {
    text-align:center;
    margin: 10px auto;
    max-width: 1070px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-wrap: wrap;

    .top-text-img {
        width: auto;

        img {
            border: 3px solid $grey;
            border-radius: 4px;
        }
    }

    .top-text-text {
        width: 70%;
        text-align: left;
        padding: 10px 20px;
    }
}

.development-top-btn {
    text-align:center;
    margin: 50px auto;
    max-width: 1070px;

    a {
        display: block;
        background-color: #97be1e;
        color: #FFFFFF;
        font-weight: lighter;
        max-width: 450px;
        padding: 10px;
        margin: 0 auto;
        text-transform: uppercase;
    }
}

.development-middle-img {
    text-align: center;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    margin: 10px auto 50px auto;
    padding: 40px;
}

.development-middle-text {
    text-align:left;
    margin: 10px auto 50px auto;
    max-width: 1070px;

    span {
        font-weight: bold;
    }
}

.development-bottom-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto 50px auto;
    max-width: 1070px;
    justify-content: center;

    .bottom-flex-item {
        width: 33.33%;
        min-width: 200px;
        position: relative;

        .flex-item-img {
            border: 5px solid $grey;
            margin:10px auto;
            width: 250px;
            height:250px;
            border-radius: 50%; 
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                opacity: 0.6;
                transition: opacity .3s;
    
            }
        }

        .flex-item-text {
            width: 240px; 
            height:auto;
            margin: 0 auto;
            text-align: justify;
            font-style: italic;

            span {
                font-weight: bold;
            }
        }

        .flex-item-img-1 {
            background-image: url(/public/img/transportvert.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .flex-item-img-2 {
            background-image: url(/public/img/pourcent.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .flex-item-img-3 {
            background-image: url(/public/img/accord.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}