.home-middle {
    position: relative;
    text-align: center;
    h2 {
        /*border-bottom: 2px dotted #ffffff;*/
        color: #515151;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        margin-right: 1px;
        text-shadow: 4px 2px #cecece;
        text-transform: uppercase;
        padding-top: 100px;
    }

    .home-middle-blue {
        position: relative;
        display: flex;
        background-color: #d9edf7;
        margin: 0 auto 20px auto;
        width: 75%;
        align-items: center;

        * {
            width: 100%;
        }

        i {
            width: 30px;
            font-size: 20px;
        }

        div.text {
            font-size: 16px;
            padding: 10px;
            text-align: left;
        }

    }

    .home-middle-yellow {
        position: relative;
        display: flex;
        background-color: #fcf8e3;
        margin: 0 auto 20px auto;
        width: 75%;
        align-items: center;

        * {
            width: 100%;
        }

        i {
            width: 30px;
            font-size: 20px;
        }

        div.text {
            font-size: 16px;
            padding: 10px;
            text-align: left;
        }

    }
}
.home-middle-img {
    position: relative;
    max-height: 250px;
    background-image: url('/public/img/pneu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 1;
}
.home-middle-img-full {
    position: relative;
    overflow: visible;
    z-index: 1;
}

.mac-middle-slider {

    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    background-color: #FFFFFF;
    z-index: 2;
    height: auto;
    margin: auto;

    .mac-middle-slider-bg {
        z-index: 1;
        width: 60%;
        height: auto;
        position: relative;
        margin: auto;

        img {
            width: 100%;
        }
    }

    .mac-middle-slider-content {
        background-color: #FFFFFF;
        width: 70%;
        margin: auto;
        height: 85%;
        border-radius: 10px 10px 0 0;
        color: white;
        z-index: 2;
        position: absolute;
        left: calc(50% - 35%);
        top: 5%;
        overflow: hidden;

        .slick-slide  {
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }
    }

    img {
        width: 100%;
    }
}

.mac-middle-slider-visible {
    overflow: visible;
    border-bottom: 3px solid rgba(91, 37, 128, 0.7);
    margin-bottom: 20px;
    padding-bottom: 50px;

    .mac-middle-slider-content {
        overflow: visible;
    }
}

.middle-slider-items {
    position: relative;
    padding: 10px;
    background-image: url('/public/img/pneu.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 350px;

    .mac-middle-slick-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin:0;
        padding:0;
        position: relative;
        list-style: none;
        height: 200px;

        li {

            margin: 3px;
            border: 1px solid transparent;
            background-color: transparent;
            padding: 10px 20px;
            min-width: 200px;

            button {
                margin: 0;
                border: 0;
                background-color: transparent;
                padding: 0;
                color: #80a316;
                transition: background .3s, padding .3s;
            }

            .slider-content-nouveau-marche {
                background-image: url(/public/img/picto-main.png);
                background-repeat: no-repeat;
                background-position: top;
                background-size: auto 40%;
                padding-top: 50px;
            }

            .slider-content-reservation-administrative {
                background-image: url(/public/img/picto-gestiondoc.png);
                background-repeat: no-repeat;
                background-position: top;
                background-size: auto 40%;
                padding-top: 50px;
            }

            .slider-content-reglementation-co2 {
                background-image: url(/public/img/picto-co2.png);
                background-repeat: no-repeat;
                background-position: top;
                background-size: auto 40%;
                padding-top: 50px;
            }

            .slider-content-centrale-achat {
                background-image: url(/public/img/picto-centrale.png);
                background-repeat: no-repeat;
                background-position: top;
                background-size: auto 40%;
                padding-top: 50px;
            }
        }

        .slick-active {
            /*border: 1px solid #5b2580;*/
            background-color: transparent;

            button {
                background-color: transparent;
                color: #5b2580;
                background-size: auto 50%;
                padding-top: 70px;
                transition: background .3s, padding .3s;
            }
        }
    }
}

.cube {
    height: 550px;
    margin: 0 auto;
    text-align: center;
    width: 1050px;
    position: relative;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;

    .cube-item-1 {
        background-image: url('/public/img/camionnette2.jpg');
    }

    .cube-item-2 {
        background-image: url('/public/img/postman2.jpg');
    }

    .cube-item-3 {
        background-image: url('/public/img/professional2.jpg');
    }

    .cube-item-4 {
        background-image: url('/public/img/dock2.jpg');
    }

    .cube-item-5 {
        background-image: url('/public/img/sunset.jpg');
    }

    .cube-item-6 {
        background-image: url('/public/img/handshake.jpg');
    }

    .cube-item-7 {
        background-image: url('/public/img/avion.jpg');
    }

    .cube-item-8 {
        background-image: url('/public/img/driver.jpg');
    }

    .cube-item {
        /*border: 1px dotted grey;*/
        width: 50%;
        padding: 10px;
        cursor: pointer;
        /*padding-top: 12%;*/
        font-size: 28px;
        height: 274px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-width: 200px;

        &:hover {
            background-image: inherit;
            background-position: inherit;
            background-repeat: inherit;
            background-size: inherit;
        }
    }

    .cube-round {
        width: 200px;
        height: 200px;
        position: absolute;
        top: calc(50% - 100px);
        border-radius: 50%;
        text-transform: uppercase;
        font-weight: bold;
        background-color: $white;
        font-size: 18px;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .cube-round-green {
        border: 20px solid $greendark;
    }

    .cube-round-purple {
        border: 20px solid $purpledark;
    }
}

.cube-green {
    border: 1px solid $greendark;

    .cube-item {
        background-color: $greenligth;
        color: $white;
        &:hover {
            background-color: $greendark;
        }
    }
}

.cube-purple {
    border: 1px solid $purpledark;
    margin-top: 60px;

    .cube-item {
        background-color: $purpleligth;
        color: $white;
        &:hover {
            background-color: $purpledark;
        }
    }
}

.home-about {
    max-width: 1050px;
    margin: 50px auto 50px auto;
    background-color: #ededed;
    border-radius: 4px;

    h2 {
        color: $andcogreen;
        padding: 10px 20px;
    }

    .home-about-flex {
        display:flex;
        flex-wrap: wrap;
        justify-content: center;

        .home-about-flex-jj {
            width: 50%;
            min-width: 300px;
            display: flex;
            position: relative;
            overflow: visible;
            min-height: 800px;

            .home-about-flex-img {
                background-image: url('/public/img/JJ.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                position: absolute;
                width: 400px;
                min-height: 500px;
                bottom: -1px;
                left:-100px;
            }

            .home-about-flex-text {
                background-color: #000000;
                color: #FFFFFF;
                padding: 30px 50px;
                position: absolute;
                bottom:0;
                right:0;
                width: 330px;
                height: auto;
            }
        }

        .home-about-flex-logo {
            width: 50%;
            min-width: 300px;
            min-height: 400px;
            padding: 10px;

            .home-about-logo-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .home-about-logo-rep,
                .home-about-logo-ademe,
                .home-about-logo-accord {
                    width: 33.33%;
                    height: 100px;
                    position: relative;
                    overflow: hidden;
                    background-position: top;
                    background-repeat: no-repeat;
                    background-size: auto;
                    transition: background-position .5s;

                    &:hover {
                        background-position: bottom;
                        transition: background-position .5s;
                    }
                }

                .home-about-logo-accord {
                    width: 265px;
                    height: 86px;
                }

            }

            .home-society-logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .home-society-logo {
                    width: 33.33%;
                    height: 100px;
                    position: relative;
                    overflow: hidden;

                    .home-society-logo-img {

                        position: absolute;
                        top: 5px;
                        bottom: 5px;
                        right: 5px;
                        left: 5px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        border: 1px solid #BABABA;

                        &:hover {
                            transition-duration: 0.3s;
                            transition-timing-function: ease-in-out;
                            transition-delay: 0.5s;
                            filter: grayscale(100%);
                        }

                    }
                }

            }
        }
    }
}


section#about-block {
    background-color: #ededed;
    margin-left: 10%;
    margin-right: 10%;
    h2 {
        color: $andcogreen;
    }
    p {
        background-color: $black;
        color: $white;
        padding: 1%;
    }
    p:last-child {
        text-align: right;
    }
}


.home-media-bottom-link {
    display: flex;
    justify-content: center;

    a {
        font-size: 32px;
        font-weight: bold;
        margin: 1rem;
        text-align: center;
        text-transform: uppercase;
        width: 25%;
    }
    a:hover {
        font-style: italic;
        text-decoration: underline;
    }
}
