.dashboard_sections{
    max-height: 600px;
    padding:20px;
}
.sections_content{
    height:100%;
    overflow-y: auto;
    border:1px solid #DEDEDE;
}
.tr_cron:nth-child(even){
    background-color: #DEDEDE;
}
.tr_cron:nth-child(odd){
    background-color: #FFFFFF;
}
