form {
    width: 100%;
}

textarea{
    min-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input.number-arrow-hidden::-webkit-outer-spin-button,
input.number-arrow-hidden::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].number-arrow-hidden {
  -moz-appearance: textfield;
}


.public-market-status-draft {
    color: #ffffff;
    background-color: #f3a618;
    font-size: 20px;
}

input[disabled], select[disabled], textarea[disabled] {
    color: #727272;
}

textarea, input, select, button {
    font-family: 'Open Sans', sans-serif;
    padding: 10px;
    font-weight: 200;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
    background-color: $white;
}
select {
    -webkit-appearance: none;
}

.form-control {
    font-weight: 200;
}

.quill-scrolling-container {
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
}

.ql-container {
    min-height: 100px;
    font-size: 16px;
}
label.form-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .text {
        font-size: 16px;
        margin: 0;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-color: #2196F3;
        }

        &:checked ~ .checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: $greyligth;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid $white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover input ~ .checkmark {
        background-color: $bggreycheckboxdark;
    }
}
.btn-inline-right {
    border-left: 0;
}

.viapost-forum-btn {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #333333;
    font-weight: lighter;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none !important;
    color: #333333;
    transition: color .3s, background .3s;

    &:hover {
        color: #ffffff;
        background-color: #333333;
        transition: color .3s, background .3s;
    }
}


.society-form-ocr-container {
    position: relative;
    border: 1px solid $grey;
    padding: 30px 20px 30px 20px;
}

.society-form-container {
    display: flex;
    padding: 0;

    .society-form-content {
        width: calc(100% - 756px);
        padding: 10px 20px;
    }

    .society-form-email-content {
        width: 756px;
        padding: 10px 20px;
        border-left: 1px solid $grey;

        .form-add-email-template {
            display:none;
        }

        .form-add-email-field {
            position: relative;
            margin: 5px 10px 0 10px;

            label, input {
                display: block;
                width: 100%;
                span {
                    color: red;
                }
            }
            label {
                margin: 0;
                padding: 0;
            }
        }

        .form-add-email-field-btn {
            position: relative;
            margin-top: 10px;
            margin: 10px 10px 0 10px;

            button {
                width: 100%;
                border: 1px solid $black;
                color: $white;
                background-color: $black;
                border-radius: 4px;
                opacity: 1;
                transition: opacity .3s;
                padding: 5px;
                cursor: pointer;
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
        }

        .email-form-title {
            display: flex;
            padding: 5px;

            .email-form-title-text {
                width: 100%;
                font-size: 22px;
                font-weight: bold;
            }

            .email-add-new-template {
                width: auto;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;
                &:hover {
                    opacity: .5;
                    transition: opacity .3s;
                }
            }
        }

        .form-email-content {
            /*border:1px solid $grey;*/
            padding: 10px;

            .form-email-list-label {
                border-bottom: 1px solid $grey;
                margin-bottom: 10px;
                margin-top: 10px;
                display: flex;
                align-items: center;

                .email-list-label-text {
                    width: 100%;
                }

                .email-list-label-icon {
                    width: 40px;
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity .3s;
                    &:hover {
                        opacity: 0.5;
                        transition: .3s;
                    }
                }
            }

            .form-email-list-container {
                list-style: none;
                margin: 0;
                padding: 0;

                a {
                    color: inherit;
                }

                .form-email-list-item {
                    padding: 5px;

                    .form-email-list-item-flex {
                        display: flex;
                        align-items: center;
                    }


                    .custom-switch {
                        width: auto;
                    }

                    .email-template-period-options {
                        width:40px;
                        text-align:center;
                        cursor:pointer;
                        opacity: 1;
                        transition: opacity .3s;
                        &:hover {
                            opacity: 0.5;
                            transition: .3s;
                        }
                    }

                    .form-email-item-name {
                        width: 100%;
                        text-align: left;
                    }


                    .email-template-edit,
                    .email-template-remove,
                    .email-template-view {
                        width: auto;
                        padding: 0 5px;
                        cursor:pointer;
                        opacity: 1;
                        transition: opacity .3s;
                        &:hover {
                            opacity: 0.5;
                            transition: .3s;
                        }
                        border: 1px solid $grey;
                        margin: 0 2px;
                        border-radius: 4px;
                        font-size: 14px;
                    }

                    .email-template-remove {
                        &:hover {
                            color: red;
                        }
                    }

                    .email-template-view {
                        &:hover {
                            color: green;
                        }
                    }
                }
            }
        }
    }
}

.form-email-template-period {
    position: relative;
    display: none;
    z-index: 10;
    top: 10%;
    text-align:center;
    padding-bottom: 40px;

    .form-email-template-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        padding: 5px;
        z-index:50;
        opacity: 1;
        transition: opacity .3s;
        background-color: $black;
        color: $white;

        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }

    .form-email-template-title {
        position: relative;
        margin-top: 20px;
        text-align: left;
        padding: 10px 20px;
        font-size: 28px;
        font-weight: bold;
    }

    .form-email-template-items-label {
        position: relative;
        padding: 10px 10px 0 10px;
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid $grey;
        display: flex;
        align-items: center;


        .items-label-text {
            width: 100%;
        }

        .items-label-icon {
            position: relative;
            width: 40px;
            text-align: center;
            transform: rotate(0deg);
            transition: transform .3s;

            label {
                cursor: pointer;
            }

            input[type="radio"] {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: -1;
            }
        }

        .label-icon-btn-open {
            transform: rotate(180deg);
            transition: transform .3s;
        }
    }

    .form-email-template-items {
        position: relative;
        padding: 10px;
        margin: 0 5px;

        .template-item-label {
            position: relative;
            text-align: left;
            margin: 5px 0 0 0;
            font-weight: bold;
        }

        .template-item-flex {
            display: flex;
            justify-content: flex-start;
            margin: 0 0;

            .item-flex {
                width: auto;
                white-space: nowrap;
                padding: 0 10px;
                font-size: 13px;
                //border: 1px dotted grey;
            }
        }

        .template-item-flex-date {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
            //border: 1px dotted grey;
            margin: 5px 0;
            padding: 5px;

            .item-flex-date {
                width: auto;
                display: flex;
                align-items: center;
                .radio-date-field {
                    width: 30px;
                }
                label {
                    display:block;
                    width: 100%;
                    white-space: nowrap;
                }
            }
        }

        .template-item-container {
            display: none;
            position: relative;
            padding: 10px;
        }

        .template-item-container-visible {
            display:block;
        }

        .template-email-list-container {
            display:none;
        }

        /*.switch-center {
            display: flex;
            align-items: center;
            border: 1px dotted red;

            input {
                border: 1px dotted blue;
            }

            label {
                border: 1px dotted grey;
            }
        }*/


        .template-item-add-date {
            position: relative;
            display: flex;
            padding: 5px;
            justify-content: flex-start;
            padding: 5px;
            margin: 0 5px;

            i {
                width: auto;
                cursor: pointer;
                padding: 10px;
                border: 1px solid $black;
                opacity: 1;
                transition: opacity .3s;
                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
        }

        .template-item-remove-date {
            border: 1px solid $black;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                opacity: 0.5;
                transition: opacity .3s;
            }
        }
    }
}

.form-email-template-period,
.form-email-static {
    button {
        border: 1px solid $black;
        color: $white;
        background-color: $black;
        border-radius: 4px;
        opacity: 1;
        transition: opacity .3s;
        padding: 5px;
        cursor: pointer;
        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }
}

.filters-table {
    //border-top:1px solid #dee2e6;
    border-left:1px solid #dee2e6;
    border-right:1px solid #dee2e6;
    display:flex;
    justify-content:flex-end; 
    //align-items: center;
    //background-color: #dee2e6;

    form {
        width: 100%;
        display: flex;
    
        input, select {
            width: 100%;
            border: 0;
            border-radius: 0;
            background-color: white;
            border-top:1px solid #dee2e6;
        }

        button {
            width: auto;
            border: 0;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-left: 1px solid #dee2e6;
            border-right: 1px solid #dee2e6;
            border-top:1px solid #dee2e6;
        }

        .field-br {
            border-right: 1px solid #dee2e6;
        }
    }

    a.search-clean {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: inherit;
        text-decoration: none;
        border-left: 1px solid #dee2e6;
        border-right: 1px solid #dee2e6;
        border-top:1px solid #dee2e6;
        background-color: white;
        margin: 0 0 0 5px;
    }

    .filters-options {
        width:auto;
        display:flex;
        margin-left: 5px;
        //border-right: 1px solid red;

        .item-option {
            width:auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-left: 1px solid #dee2e6;
            border-top:1px solid #dee2e6;

            a {
                display: block;
                padding: 10px;
            }
        }
    }

    form.filter-number-item {
        border-left: 1px solid #dee2e6;
        margin-left: 5px;

        /** {
            &:last-child {
                border-right:0;
            }
        }*/
    }
}

.filters-no-br  {
    border-right: 0;
}

.public-market-lot-nav {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    padding: 10px;
    z-index:20;
    background-color:#ffffff;

    .lot-nav-label {
        border:1px solid #e9ecef;
        padding: 5px;
        font-size: 11px;
        margin: 3px;
        width: 50px;
        text-align:center;
        text-transform: uppercase;
    }

    .public-market-lot-nav-item {
        border:1px solid #e9ecef;
        padding: 5px;
        font-size: 11px;
        margin: 3px;
        width: auto;
        text-align:center; 
        display: none;
        white-space: nowrap;
    }

    .public-market-lot-nav-item-green {
        border:1px solid $andcogreen;
        color: $white;
        background-color: $andcogreen;
    }

    .public-market-lot-nav-item-purple {
        border:1px solid $andcopurple;
        color: $white;
        background-color: $andcopurple;
    }
}

.public-market-lot-nav-description{

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;

    .item-green,
    .item-white{

        display: flex;
        gap: 15px;
        align-items: center;

        &__box{
            border: 1px solid #e9ecef;
            width: 50px;
            height: 30px;
        }

        &__description{
            font-style: italic;
        }
    }

    .item-green{
        &__box{
            background: #97be1e;
        }
    }
    .item-white{
        &__box{
            background: #fff;
        }
    }
}

.public-market-lot-container {

    display: none;

    .public-market-lot-flex {

        display: flex;
        //align-items:flex-start;
        align-items: center;
        border: 1px solid #e9ecef;
        margin: 10px;
        width: calc(100% - 15px);
        

        .item-lot-number {
            width: 100px;
            padding: 10px;

            .lot-number {
                text-align:center;
                //padding-top: 20px;
                text-transform: uppercase;
            }
        }

        .item-lot-form {
            width:calc( 100% - 100px);
            padding: 10px;
            border-left: 1px solid #e9ecef;

            .item-lot-fields-container {
                width: 100%;

                .item-lot-fields {
                    display: flex;
                    gap: 3px;
                    align-items: center;

                    div.form-group {
                        width: 100%;
                        margin: 0 0 0 0;

                        .input-group {
                            display: flex;
                            gap: 5px;
                            .input-group-prepend {
                                display:none;
                            }

                        }

                        
                    }
                }
            }

            
        }
    }
}

.list-lot-files {
    list-style: none;
    margin:0;
    padding:0;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 5px;

    li.label {
        border-bottom:1px dashed #e9ecef;
        padding: 5px;
        margin: 5px 0; 
    }

    li.file {
        //border:1px dotted red;
        padding: 3px;
        margin:3px 10px;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 13px;

        a.file-text {
            width: calc(100% - 25px);
            
        }

        a.file-delete {
            width: 25px;
            &:hover {
                color: $red;
            }
        }
    }
}

.custom-flex-item {
    .form-group {
        margin-bottom: inherit;
        margin-top: 5px;
    }
}

.custom-file {
    height: auto;
}



// PUBLIC MARKET

.publicmarket-subcontractors-container {
    position: relative;

    .publicmarket-subcontractors-title {
        position:relative;
        padding:5px 0;
        margin: 5px;
    }

    .publicmarket-subcontractors-search {
        position:relative;
        padding:5px 0;
        margin: 5px;
        display:flex;
        align-items:center; 
    }

    .container-action-table-subcontractors {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;

        .container-checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: 5px;
        }

        .publicmarket-subcontractors-list-check-all-reset {
            border: 1px solid;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            padding: 0.5rem 1rem;

            &:hover {
                background: $andcopurple;
                color: #fff;
            }
        }
    }

    .publicmarket-subcontractors-list-count {
        padding: 5px;
    }

    .publicmarket-subcontractors-list-btn {
        padding: 0.5rem 1rem;
        border: 1px solid;
        cursor: pointer;
        margin:0;
    }

    .publicmarket-subcontractors-list-btn-active {
        background: $andcopurple;
        color: #fff;
    }

    /*.publicmarket-subcontractors-list-check-all,
    .publicmarket-subcontractors-list-check-all-page,
    .publicmarket-subcontractors-list-check-all-absolute {
        position: relative;
        input {
            visibility: hidden;
            position: absolute;
            opacity: 0;
        }
        label {
            padding: 0.5rem 1rem;
            border: 1px solid;
            cursor: pointer;
            margin:0;
        }
        input:checked + label {
            background: $andcopurple;
            color: #fff;
        }
    }

    .publicmarket-list-check-all-hidden {
        display: none;
    }*/

}
.publicmarket-subcontractors-container[disabled] {
    .publicmarket-subcontractors-list-check-all {
        display: none;
    }
    tbody {
        //pointer-events: none;
    }
}

input[type="checkbox"][name="all_subcontractors[]"] {
    display:none;
}

.btn-lot {
    
    font-size: 10px;
    padding: 5px;
    display: inline-block;
    width: auto;
    text-align:center;
    text-decoration: none !important;
    font-weight: 400;
    text-transform: uppercase;
    margin: 5px 5px 10px 0;

    i {
        width: 20px;
        font-size: 12px;
    }

    div {
        width: calc(100% - 20px);
        text-align: left;
        padding: 0 10px;
    }
}

.btn-file-show {
    color: #bababa;
    border:1px solid #bababa;

    &:hover {
        color: #ffffff;
        background-color: #bababa;
        border:1px solid #bababa;
    }
}

.btn-file-delete {
    color:#e06464;
    border:1px solid #e06464;

    &:hover {
        color: #ffffff;
        background-color: #e06464;
        border:1px solid #e06464;
    }
}

#subcontractor_list_form_btn {
    margin: 0 0 0 10px;
    cursor:pointer;
    border:1px solid #ced4da;
    padding: 5px;
}

#subcontractor_list_form_clear {
    margin: 0 0 0 10px;
    cursor:pointer;
    border:1px solid #ced4da;
    padding: 5px;
}

#subcontractor_list_form_result_notice {
    position:relative;
    padding: 0 5px;
    display: none;
}

#subcontractor_list {
    position:relative;
    //max-height: 400px;
    //overflow:auto;
    border: 1px solid $andcopurple;
    padding:10px;
    margin: 5px;
    //background-color: rgba(236, 179, 253, 0.1);
    display: flex;
    flex-wrap: wrap;

    .list-item-container {

        position: relative;
        padding: 10px;
        margin: 10px 5px;
        border-radius: 4px;
        background-color: white;
        width: calc(25% - 10px);
        min-width: 350px;

        .list-item {
            display: flex;
            align-items:center;
            font-size: 13px;

            .checkbox {
                padding: 5px;
                display:flex;
                align-items:center;
            }
        }

        .list-item-license {
            font-size: 11px;
            margin-bottom: 5px;

            .license-item {
                display:flex;
                align-items:center;

                .type {
                    padding: 1px 5px;
                    min-width: 30px;  
                }

                .validated {
                    padding: 1px 5px;
                    margin: 0 5px; 
                }
            }
        }

        .list-item-zone {
            position:relative;
            font-size: 11px;
            margin-bottom: 20px;

            .zones {
                display: flex;
                flex-wrap:wrap;
                .zone {
                    border:1px solid grey;
                    padding:2px;
                    margin:2px;
                    font-size: 10px;
                }
            }
        }
    }

    .flex-items {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        div {
            margin:3px;
        }
        .zone-item {
            border:1px solid #bababa;
            padding:4px;
            font-size:11px;
        }
    }

    #subcontractor_list_table_wrapper {
        width: 100%;
        > div:nth-child(1) {
            > div:nth-child(2) {
                display: block;
                max-width: 100%;
                label,
                input{
                    width: 100%;
                }
            }
        }
        #subcontractor_list_table {
            table-layout: fixed;
            td {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            tbody {
                td:nth-child(7),
                td:nth-child(8),
                td:nth-child(9) {
                    text-align: right;
                }
                td:nth-child(11) {
                    text-transform: uppercase;
                }
            }
        }
    }

    #subcontractor_list_table_filter {
        text-align: right;
    }
    #subcontractor_list_table_paginate {
        display: flex;
        justify-content: flex-end;
    }

    table.dataTable tbody tr.selected, table.dataTable tbody th.selected, table.dataTable tbody td.selected {
        color: inherit;
    }
    table.dataTable tbody > tr.odd.selected, table.dataTable tbody > tr > .odd.selected {
        background-color: rgba(0, 0, 0, 0.05);
    }
    table.dataTable tbody > tr.even.selected, table.dataTable tbody > tr > .even.selected {
        background-color: inherit;
    }
    table.dataTable tr.selected td.select-checkbox::after, table.dataTable tr.selected th.select-checkbox::after {
        text-shadow: none;
        font-family: monospace;
    }

}

.publicmarket-subcontractors-loader {
    display:none;
    padding: 10px;
    text-align:center;
    font-size:22px;
    color: $andcopurple;
    border: 1px solid $andcopurple;
    margin: 10px 5px;
    animation-duration: 1s;
    animation-name: flashing;
    animation-iteration-count: infinite;
    transition: none;
    background-color: #fdf7ff;
    position: sticky;
    bottom: 60px;
    z-index: 1;
}
.publicmarket-subcontractors-loader span {
    display: inline-block;
    word-break: break-word;
}

@keyframes flashing {
    0%   { opacity:1; }
    40%   {opacity:0; }
    100% { opacity:1; }
}

#response_form_subcontracor.response-subcontractor-container {
    background-color: #fafafa;
}

#response_form_subcontracor.response-subcontractor-container > .container-flex, 
#response_subcontracor.response-subcontractor-container 
{
    position:relative;
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-start;

    .items-container-sizer {
        width:calc(50% - 10px);
        margin:5px;
    }

    .items-container {
        border:1px solid #e9ecef;
        //width:calc(50% - 10px);
        width:100%;
        padding: 10px;
        margin:5px;
        min-width: 400px;
        background-color: #ffffff;
        border-radius: 4px;

        .item {
            //border:1px dashed #e9e9e9;
            margin:5px;

            label {
                font-weight: bold;
                margin:0;
            }

            div {
                display: flex;
                align-items: center;

                .index {
                    border: 1px solid #333333;
                    padding: 3px;
                    margin: 0 0 0 3px;
                    font-size: 10px;
                    color: #ffffff;
                    display: flex;
                    align-items:center;
                    background-color: #333333;
                    border-radius: 2px;
                }
            }

            div a {
                display:flex;
                align-items: center;

                i {
                    margin: 0 5px;
                }
            }

            .calc-notice {
                font-size: 10px;
                padding: 0 5px;
                color: #BABABA;
            }

            .calc-notice-active {
                color: $andcopurple;
            }
        }

        .item-hidden {
            display: none;
        }
    }

    h2.items-container {
        width: calc(100% - 10px);
        border-bottom: 3px solid #e9ecef;
    }

    label span {
        color: red;
        font-size: 12px;
        padding: 0 5px;
    }

    input[type=text], input[type=number], input[type=time], select, textarea {
        width:100%;
        min-width: inherit;
    }

    input[readonly], select[readonly], textarea[readonly] {
        background-color: #e7e7e7;
        color: #727272;
        border: 1px solid #727272;
    }

    .items-vehicles, .items-drivers {
        display:none;
    }
    .items-vehicles-visible, .items-drivers-visible {
        display:block;
    }
}

#run_field {

    margin: 0 5px;
    display:flex;
    align-items:center;
    font-size: 24px;

    div {
        width: 100%
    }

    .turn-text-container {
        margin: 10px 0;
        font-size: 28px;
    }

    .is-lastrun-field {
        padding: 0;
        width: auto;
        text-align: right;
        border:1px solid #e9ecef;
        white-space: nowrap;
        margin-top: 5px;
    
        .form-group {
            margin: 0;
    
            .form-check {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
    
                .form-check-input {
                    position: relative;
                    margin:0 10px;
                    padding:0;
                }

                label {
                    border-left:1px solid #e9ecef;
                    padding: 10px;
                }
            }
        }
    }
}

#response_subcontracor_lots {
    .lot-item {
        border:1px solid #e9ecef;
        display: flex;
        margin: 10px 15px;

        .item-index {
            border-right:1px solid #e9ecef;
            width:20%;
            display:flex;
            align-items:center;
            justify-content:center; 
        }

        .item-datas {
            border-right:1px solid #e9ecef;
            width:60%;
            padding: 10px;

            .data {
                border:1px dotted grey;
                margin:5px;

                label {
                    font-weight: bold;
                    margin:0;
                }

                div a {
                    display:flex;
                    align-items: center;

                    i {
                        margin: 0 5px;
                    }
                }
            }
        }

        .item-link {
            width: 20%;
            display:flex;
            align-items:center;
            justify-content:center;
            overflow-x: none;
            overflow-y: auto;
            position: relative;
            z-index: 5;

            .response-lot-label-container {
                text-align:center;

                .response-lot-message-btn {
                    font-size: 12px;
                    padding: 10px;
                    cursor: pointer;
                    opacity: 1;
                    transition: opacity .3s;

                    &:hover {
                        opacity: 0.5;
                        transition: opacity .3s;
                    }
                }

            }

            

            .response-lot-message {
                display:none;
                position: absolute;
                top:0;
                left:0;
                right:0;
                z-index: 10;
                background-color: #ffffff;
                text-align:center;

                .response-lot-message-close {
                    position: relative;
                    border: 1px solid #333333;
                    margin: 5px;
                    cursor:pointer;
                }
            }

            
        }
    }

    .lot-item-active {
        border:1px solid $andcogreen;

        .item-index {
            border-right:1px solid $andcogreen; 
        }

        .item-datas {
            border-right:1px solid $andcogreen;
        }
    }
}

.response-lot-nav-sticky-container {
    border:1px solid transparent;
    position:fixed;
    top:145px;
    left:0;
    right:0;
    z-index: 88;
    background-color:#fafafa;
    padding:0 10px;
    margin: 0 10px;
    font-size: 11px;
    //display:none;

    &.response-lot-nav-sticky-hidden {
        display: none;
    }

    .response-lot-nav-sticky {
        display: flex;
        position: relative;
        align-items: flex-start;
        gap: 3px;
        border:1px solid transparent;

        .lot-nav-sticky-selector {
            border: 1px solid transparent;
            width: auto;
            min-width: 310px;
            max-width: 310px;
            font-size: 11px;
            select {
                width: 100%;
            }
        }

        .item-field-nav {
            border: 1px solid transparent;
            padding: 10px;
            background-color: #bababa;
            margin-top: 2px;
        }

        .lot-nav-sticky-items {
            border: 1px solid transparent;
            width: auto;
            overflow: auto;
            font-size: 11px;
            border-left: 0;
            border-right: 0;

            .sticky-items {
                display: flex;
                border: 1px solid transparent;

                .field-row-lot-container {
                    min-width: 170px;
                    max-width: 170px;
                    //border: 1px solid transparent;

                    .field-row-lot {
                        white-space: nowrap;
                        text-align: center;
                        opacity: 0.9;
                        padding: 10px 20px;
                        text-transform: uppercase;
                        border: 1px solid #bababa;
                        border-left:0;
                    }

                    .field-row-lot:first-child {
                        border-left: 1px solid #bababa;
                    }

                    .field-row-lot-response {
                        border: 1px solid $andcogreen;
                        color: $andcogreen;
                        background-color: #ffffff;
                        border-left:0;
                    }

                    .field-row-lot:first-child.field-row-lot-response {
                        border-left: 1px solid $andcogreen;
                    }
        
                    .field-row-lot-published {
                        border: 1px solid $andcogreen;
                        color: #ffffff;
                        background-color: $andcogreen;
                        border-left:0;
                    }

                    .field-row-lot:first-child.field-row-lot-published {
                        border-left: 1px solid $andcogreen;
                    }
                
                    .field-row-lot-is-out {
                        border: 1px solid rgb(190, 106, 106);
                        color: #ffffff;
                        background-color: rgb(190, 106, 106);
                        border-left:0;
                    }
                    .field-row-lot:first-child.field-row-lot-is-out {
                        border-left: 1px solid rgb(190, 106, 106);
                    }
                }
            }
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        .lot-nav-sticky-items::-webkit-scrollbar {
            display: none; 
        }
    }
}

.response-lot-label-container {
    padding: 10px 5px;
    margin: 10px 0;
}


.response-lot-btn,
._response-lot-btn {
    cursor: pointer;
    opacity: 1;
    transition: opacity .3s;
    //display: flex;
    display: block;
    text-decoration: none;
    border: 1px solid #333333;
    padding: 10px;
    color: #333333;
    text-transform: uppercase;
    text-align: center;
    width: 70%;
    //justify-content: center;
    //align-items: center;
    //align-self: flex-end;
    white-space: nowrap;
    //margin-top: 20px;
    font-size: 11px;

    &:hover {
        opacity: 0.5;
        transition: opacity .3s;
        text-decoration: none;
        color: #333333;
    }
}

.countdown-container {
    //border: 1px dotted grey;
    margin: 10px 5px;
    padding:0 10px;
    font-size: 22px;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#countdown_view {
    font-size: 32px;
    white-space: nowrap;
}

.dateend-is-expired {
    color: #e43737;
    font-weight: bold;
}

#public_market_edit {
    .countdown-container {
        padding:0 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: auto;

        #countdown_view {
            font-size: 28px;
            white-space: nowrap;
        }
    }
}

#public_market_departments_container {
    position: relative;
    overflow:hidden;
}

#public_market_departments_all {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: 50;
    display: none;

    div {
        position: absolute;
        top:30px;
        left:0;
        right:0;
        bottom:0;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ced4da;
    }
}

#public_market_modal_email_not_selected,
#public_market_modal_lot_delete {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 888;
    display:none;

    .modal-container {
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .modal-email,
        .modal-confirm-lot-delete {
            border: 1px solid #e9ecef;
            background-color: #ffffff;
            max-width: 600px;
            margin: auto;
            border-radius: 4px;

            .header {
                //border-bottom: 1px solid #e9ecef;
            }

            .content {
                padding: 10px;
                text-align: center;

                .label {

                }

                .error-empty-value {
                    font-size: 13px;
                    color: $red;
                    padding: 10px;
                    border: 1px solid red;
                    background-color: #f8d1d1;
                    margin: 5px 0;
                    display:none;
                }

                textarea {
                    min-height: 250px;
                    max-height: 500px;
                    width:100%;
                    font-size: 13px;
                    border: 1px solid #e9ecef;
                }

                .valid-text-email {
                    cursor:pointer;
                    padding: 10px;
                    border: 1px solid #e9ecef;
                }

                .close-text-email {
                    cursor:pointer;
                    padding: 10px;
                    border: 1px solid #333333;
                    color: #ffffff;
                    background-color: #333333;
                    margin-top: 5px;
                }

                p {
                    padding: 10px 5px;
                }

                .btn-flex {
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                }

                .btn-modal {
                    cursor:pointer;
                    padding: 10px;
                    border: 1px solid #e9ecef;
                    width: 100%;
                }
            }
        }
    }
}

.btn-fixed {
    position: relative;
    text-transform: uppercase;
}
.btn-fixed-active {
    position: fixed;
    bottom: 0;
    left:0;
    right:0;
    z-index : 888;
    border-radius:0;
    opacity: 1;
    width: 100%;
}


.modal-response-lot {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9990;
    display:none;

    .modal-container {
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .modal-message {
            border: 1px solid #e9ecef;
            background-color: #ffffff;
            max-width: 600px;
            min-width: 500px;
            margin: auto;
            border-radius: 4px;

            .header {
                //border-bottom: 1px solid #e9ecef;
            }

            .content {
                padding: 10px;
                text-align: center;

                .label {

                }

                .error-empty-value {
                    font-size: 13px;
                    color: $red;
                    padding: 10px;
                    border: 1px solid red;
                    background-color: #f8d1d1;
                    margin: 5px 0;
                    display:none;
                }

                textarea {
                    min-height: 200px;
                    max-height: 400px;
                    width:100%;
                    font-size: 13px;
                    border: 1px solid #e9ecef;
                }

                .btn-container {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 5px;

                    .response-lot-btn-cancel {
                        cursor:pointer;
                        padding: 10px;
                        border: 1px solid #e9ecef;
                        background-color: #ffffff;
                        display: block;
                        width: 100%;
                        margin-right: 5px;
                        text-transform: uppercase;
                        opacity: 1;
                        transition: opacity .3s;
        
                        &:hover {
                            opacity: 0.5;
                            transition: opacity .3s;
                        }
                    }
    
                    .valid-text-message {
                        cursor:pointer;
                        padding: 10px;
                        border: 1px solid #e9ecef;
                        background-color: #ffffff;
                        display: block;
                        width: 100%;
                        text-transform: uppercase;
    
                    }

                }
            }

            .footer {
                //border-top: 1px solid #e9ecef;
            }
        }
    }
}

.section-nav {
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    padding: 10px;
    z-index:20;
    background-color:#ffffff;

    .section-nav-item {
        border:1px solid #e9ecef;
        padding: 5px;
        font-size: 11px;
        margin: 3px 6px 3px 0;
        width: auto;
        text-align:center; 
        cursor:pointer;
        //display: none;
    }
}

.last-run-notice {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        padding: 10px;
        width: auto;
    }

    span {
        width: 100%;
        color: $andcopurple;
        font-weight: bold;
    }
}

.response-lot-count {
    padding: 5px;
    border-bottom: 1px dashed #BABABA;
    margin-bottom: 10px;
    position:relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
        width: 100%;
    }

    a {
        width: auto;
        white-space: nowrap;
    }
}

.response-lot-items {
    display: flex;
    align-items:center;
    flex-wrap:wrap; 

    .reponse-item {
        border:1px solid #333333;
        background-color: #ffffff;
        color: #333333;
        padding: 5px 10px;
        font-size: 10px;
        margin: 5px; 
        cursor:pointer;
        opacity: 1;
        display: block;
        transition: opacity .5s, color .5s, background .5s;
        text-decoration: none;
        &:hover {
            opacity: 0.8;
            background-color: #333333;
            color: #ffffff;
            transition: opacity .5s, color .5s, background .5s;
        }

        .reponse-item-modified {
            color: #97be1e;
            border-color: #97be1e;
        }
    }

    .reponse-item-out {
        border:1px solid #ce4c4c;
        background-color: #ffffff;
        color: #ce4c4c;
        padding: 5px 10px;
        font-size: 10px;
        margin: 5px; 
        cursor:pointer;
        opacity: 1;
        display: block;
        transition: opacity .5s, color .5s, background .5s;
        text-decoration: none;
        &:hover {
            opacity: 0.8;
            background-color: #ce4c4c;
            color: #ffffff;
            transition: opacity .5s, color .5s, background .5s;
        }
    }

    .response-modal {
        position: fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 8000;
        background-color:rgba(255, 255, 255, 0.98);
        text-align: center;
        display:none;
        overflow: auto;

        .response-modal-close {
            position: fixed;
            right: 20px;
            top: 20px;
            cursor:pointer;
            border: 1px solid #ee6262;
            color : #ee6262;
            padding: 10px;
        }

        .response-lot-form-data-container {
            border: 1px solid #BABABA;
            padding: 10px;
            margin: 25px auto;
            width: 75%;
            background-color:#FFFFFF;

            .form-data-response {
                border:1px dotted #BABABA;
                padding: 5px;
                margin:5px;  
            }
        }
    }
}

.response-compare-scroll-btn {
    display:flex;
    margin-bottom: 5px;

    .scroll-btn-left {
        width: 50%;
        text-align:left;
        i {
            cursor: pointer;
            font-size: 22px;
            border: 1px solid #bababa;
            padding: 10px;
            transition: color .3s;
            &:hover {
                color: #bababa;
                transition: color .3s;
            }
        }
    }
    .scroll-btn-right {
        width: 50%;
        text-align: right;
        i {
            cursor: pointer;
            font-size: 22px;
            border: 1px solid #bababa;
            padding: 10px;
            transition: color .3s;
            &:hover {
                color: #bababa;
                transition: color .3s;
            }
        }
    }
}

.compare-sticky-nav {
    position: fixed;
    top: 94px;
    left: 10px;
    right:10px;
    z-index: 88;
    background-color: #fafafa;
    //display:none;

    .compare-sticky-nav-items {
        display: flex;
        gap: 3px;
        font-size: 11px;

        .sticky-nav-items-label {
            border: 0;

            .sticky-nav-item-label {
                width: 350px;
                padding: 3px;
                text-align: right;
                font-weight: 600;
                background-color: #333333;
                color: #ffffff;
                border: 1px solid transparent;
                margin: 2px 0;
            }
        }

        .scroll-btn-left {
            border: 1px solid transparent;
            padding: 5px;
            background-color: #bababa;
            margin-top: 2px;
            cursor: pointer;
        }
        .scroll-btn-right {
            border: 1px solid transparent;
            padding: 5px;
            background-color: #bababa;
            margin-top: 2px;
            cursor: pointer;
        }

        .sticky-nav-items-data {
            display: flex;
            overflow-y: auto;
            width: auto;
            border: 1px solid transparent;

            .sticky-nav-item-checkbox {
                display: flex;
                padding: 3px 5px;
                text-align: right;
                display: flex;
                align-items: center;
                margin: 2px 0;
                border: 1px solid transparent;
                background-color: #ffffff;
                min-width: 200px;
                max-width: 200px;

                input, i {
                    margin: 0 10px;
                }

                i {
                    display: block;
                }

                .sticky-nav-item-checkbox-text {
                    width: 100%;
                    text-align: right;
                }
            }

            .sticky-nav-item-checkbox-odd {
                background-color: #f5dcfd;
                color: $andcopurple;
            }

            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        .sticky-nav-items-data::-webkit-scrollbar {
            display: none; 
        }
    }
}
.compare-sticky-nav-hidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0;
    z-index: -1;
}

.response-compare-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    font-size: 11px;
    background-color: #fafafa;
    gap: 3px;

    .scroll-btn-left {
        border: 1px solid transparent;
        padding: 5px;
        background-color: #bababa;
        margin-top: 2px;
        cursor: pointer;
    }
    .scroll-btn-right {
        border: 1px solid transparent;
        padding: 5px;
        background-color: #bababa;
        margin-top: 2px;
        cursor: pointer;
    }

    .compare-col-label {
        border: 1px solid transparent;
        width: 350px;
        white-space: nowrap;

        .label-item {
            //border-bottom: 1px solid #bababa;
            padding: 3px;
            text-align: right;
            font-weight: 600;
            background-color: #333333;
            color: #ffffff;
            border: 1px solid transparent;
            margin: 2px 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .label-item-title {
            padding: 5px 3px;
            text-align: left;
            margin: 2px 0;
            font-size: 14px;
            font-weight: bold;
        }

        .label-item-title-big {
            margin: 2px 0px;
            color: #bababa;
            font-size: 24px;
            font-weight: bold;
            text-align: left;
            padding-top: 10px;
            //text-transform: uppercase;
        }

        .d-conducteur, .v-véhicule {
            padding: 3px;
            text-align: right;
            font-weight: 600;
            background-color: transparent;
            color: #333333;
            border: 1px solid #333333;
            margin: 2px 0;
        }
    }

    .compare-col-data {
        width: auto;
        border: 1px solid transparent;
        overflow-y: auto;
        display: flex;

        .compare-col-data-row {
            white-space:nowrap;
        }

        .data-item {
            //border-bottom: 1px solid #bababa;
            //border-right: 1px solid #bababa;
            padding: 3px 5px;
            text-align: right;
            font-weight: 200;
            margin: 2px 0;
            border: 1px solid transparent;
            background-color: #ffffff;
            min-width: 200px;
            max-width: 200px;
            display: flex;
            align-items: center;

            .data-item-text {
                width: 100%;
                text-align: right;
            }
        }
        .data-item-hidden {
            visibility: hidden;
            background-color: transparent;
        }

        .data-item-odd {
            background-color: #f5dcfd;
            color: $andcopurple;
        }

        .data-item-empty {

            padding: 3px 5px;
            text-align: right;
            font-weight: 200;
            margin: 2px 0;
            border: 1px solid transparent;
            background-color: #fafafa;
            min-width: 200px;
            max-width: 200px;
            display: flex;
            align-items: center;

        }

        .data-item-checkbox {
            //border-bottom: 1px solid #bababa;
            //border-right: 1px solid #bababa;
            padding: 3px 5px;
            text-align: right;
            display: flex;
            align-items: center;
            margin: 2px 0;
            border: 1px solid transparent;
            background-color: #ffffff;
            min-width: 200px;
            max-width: 200px;

            input, i {
                margin: 0 10px;
            }

            i {
                display: block;
            }

            .data-item-checkbox-text {
                width: 100%;
                text-align: right;
            }
        }

        .data-item-checkbox-odd {
            background-color: #f5dcfd;
            color: $andcopurple;
        }

        .data-item-title-empty {
            //border: 1px dotted grey;
            padding: 5px 3px;
            text-align: right;
            margin: 2px 0;
            font-size: 14px;
            font-weight: bold;
        }

        .data-item-title-big-empty {
            margin: 2px 0px;
            color: #bababa;
            font-size: 24px;
            font-weight: bold;
            text-align: left;
            padding-top: 10px;
        }
    }
}

.public-market-extract-container {
    position :relative;
    padding: 10px;

    .public-market-extract-btn {
        display:flex;
        justify-content:flex-start;
        margin-top: 20px;

        a {
            border: 1px solid #bababa;
            margin: 0 10px 0 0;
            padding: 10px 20px;
            display: block;
            text-decoration: none;
            color: inherit;
            transition: color .3s, border .3s;

            &:hover {
                color: green;
                border: 1px solid green;
                transition: color .3s, border .3s;
            }

            span {
                text-transform: uppercase;
            }
        }
    }
}

#public_market_dieselIndexAt_day,
#public_market_dieselIndexProAt_day,
#public_market_gasIndexAt_day,
#public_market_engieIndexAt_day {
    display: none;
    opacity:0;
    visibility: hidden;
}

.brd-e9ecef {
    border:1px solid #e9ecef;
}

.pd-10-5 {
    padding: 10px 5px;
}

.contact-btn-container {
    display:flex;
    justify-content:flex-start;
    gap:5px;
    padding: 0 5px;

    .contact-btn, .params-btn {
        border:1px solid #e9ecef;
        padding:0;
        display:flex;
        align-items:center;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;
        color: #333333;
        text-decoration: none !important;
        font-size: 11px;
        text-transform: uppercase;

        i {
            padding: 5px 10px;
            color: $black;
            transition: color .3s;
            font-size: 13px;
        }

        span {
            padding: 10px;
            border-left: 1px solid #e9ecef;
            display: block;
        }

        &:hover {
            opacity: 0.9;
            border:1px solid $purple;
            color: $purple;
            transition: border .3s, opacity .3s, color .3s;

            i {
                color: $andcopurple;
                transition: color .3s;
            }
        }
    }
}

.field-alert {
    border: 1px solid red !important;
}

.publicmarket-close-offer-container {
    position: relative;
    border: 1px solid $andcopurple;
    padding:10px;
    margin: 20px 5px 20px 5px;

    h2 {
        color: $andcopurple;
    }

    form {

        label {
            span {
                color: red;
            }
        }

        input {
            width:100%;
        }
    }
}

.publicmarket-closed-offer-container {
    position: relative;
    //margin: 20px 5px 20px 5px;
    margin: 0 5px 20px 5px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: flex-start;

    .publicmarket-closed-offer-col {
        width: 50%;
        //border: 1px solid #e9ecef;
        border: 1px solid $andcopurple;
        background-color: rgba(226, 187, 248, 0.1);
        padding:10px;

        table {
            background-color: #ffffff;
        }
    }

    h2 {
        color: #333333;
    }

    form {

        label {
            span {
                color: red;
            }
        }

        input {
            width:100%;
            //border: 1px solid #e9ecef;
            border: 1px solid $andcopurple;
        }

        button {
            //border: 1px solid #e9ecef;
            border: 1px solid $andcopurple;
            transition: border .3s;

            &:hover {
                border: 1px solid #333333;
                transition: border .3s;
            }
        }
    }
}

.publicmarket-repport-container {
    position: relative;
    //border: 1px solid #e9ecef;
    border: 1px solid $andcopurple;
    background-color: rgba(226, 187, 248, 0.1);
    margin: 20px 5px 20px 5px;

    .label {
        position: relative;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i {
            border: 1px solid #e9ecef;
            padding: 10px;
        }

        div {
            border-bottom: 1px solid #e9ecef;
            padding: 0 10px;
        }

    }

    a{
        position: relative;
        padding: 10px;
    }
}


#response_form_subcontractor {

    input[type=number], select, input[type=text] {
        //text-align: right;
    }

    .item-field-nav i, .col-field-nav i {
        padding: 10px 0;
    }

    #add_column_energy_button:hover:not(:disabled) {
        color: #ffffff;
        background-color: #92B655;
    }

}

.ajax-upload-wrapper {

    position:relative;
    width:100%;

    .ajax-upload-label-tmp {
        position: relative;
        font-size: 12px;
        padding: 5px;
        border-bottom: 1px solid #e7e7e7;
        margin-bottom:5px;
    }

    .ajax-upload-label-text {
        position: relative;

        .ajax-upload-label-text-required {
            color: red;
            padding: 0 10px;
        }
    }

    .ajax-upload-label {
        border: 1px solid #BABABA;
        padding: 5px;
        display: block;
        position: relative;
        overflow: hidden;
        min-height: 50px;
        width:100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(182, 182, 182, 0.2);
        flex-direction: column;
        background: linear-gradient(-45deg, rgba(182, 182, 182, 0.2) 12.5%, #fff 12.5%, #fff 37.5%, rgba(182, 182, 182, 0.2) 37.5%, rgba(182, 182, 182, 0.2) 62.5%, #fff 62.5%, #fff 87.5%, rgba(182, 182, 182, 0.2) 87.5%);
        background-size: 80px 80px;
        background-position: 50px 50px;
        cursor: pointer;

        i {
            font-size: 24px;
            margin: 0 0;
        }

        span {
            font-size: 14px;
            display: block;
        }

        input[type=file] {
            opacity: 0;
            position: absolute;
        }

        .ajax-upload-label-file {
            display: flex;
            font-size: 11px;
            gap:2px;
            justify-content: flex-start;
            align-items:center;
            margin: 2px 0;

            div {
                border:1px dotted grey;
                padding: 2px;
            }
        }

        &:hover {
            border: 1px solid #604B88;
            background-color: rgba(99, 21, 99, 0.2);
            background: linear-gradient(-45deg, rgba(99, 21, 99, 0.2) 12.5%, #fff 12.5%, #fff 37.5%, rgba(99, 21, 99, 0.2) 37.5%, rgba(99, 21, 99, 0.2) 62.5%, #fff 62.5%, #fff 87.5%, rgba(99, 21, 99, 0.2) 87.5%);
        }
    }

    .ajax-upload-label-locked {
        /*display:none;*/
        background-color: rgba(27, 27, 27, 0.2);
        background: linear-gradient(-45deg, rgba(27, 27, 27, 0.2) 12.5%, #fff 12.5%, #fff 37.5%, rgba(27, 27, 27, 0.2) 37.5%, rgba(27, 27, 27, 0.2) 62.5%, #fff 62.5%, #fff 87.5%, rgba(27, 27, 27, 0.2) 87.5%);

        i {
            color: rgb(95, 95, 95);
        }
    }

    .ajax-upload-list-files {
        //border: 1px dashed #BABABA;
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;

        .ajax-upload-list-file {
            display: flex;
            font-size: 12px;
            gap:5px;
            justify-content: flex-start;
            align-items:center;
            margin: 2px 0;
            padding: 0;

            .ajax-upload-file {
                width:100%;
                padding: 5px;
                white-space: nowrap;
            }
            .ajax-upload-file-invalid {
                color: red;
            }

            a {
                border: 1px solid #BABABA;
                display:block;
                padding: 5px;

            }
        }

        .ajax-upload-list-file-loader {
            justify-content: center;

            i {
                -webkit-animation:spin 1s linear infinite;
                -moz-animation:spin 1s linear infinite;
                animation:spin 1s linear infinite;
            }
        }
    }
}

.public-market-reponses-lot-link-container-do {
    display: flex;
    justify-content: flex-end;
    padding: 5px;

    a {
        display: block;
        border: 1px solid $andcopurple;
        background-color: $andcopurple;
        padding: 10px 40px;
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            border: 1px solid $andcopurple;
            color: $andcopurple;
            background-color: #ffffff;
        }
    }
}

.public-market-reponses-lot-link-container {
    display: flex;
    justify-content: flex-end;
    padding: 5px;

    a {
        display: block;
        border: 1px solid $andcogreen;
        background-color: $andcogreen;
        padding: 10px 40px;
        color: #ffffff;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            border: 1px solid $andcogreen;
            color: $andcogreen;
            background-color: #ffffff;
        }
    }
}

.response-form-cell-container {
    border: 1px solid transparent;
    /*height: 80vh;*/
    font-size: 11px;
    position: relative;
    padding: 10px;

    .response-form-col-flex-container {
        border: 1px solid transparent;
        display: flex;
        align-items: flex-start;
        gap: 3px;
        font-size: 11px;

        .col-flex-label-container {
            border: 1px solid transparent;
            width: auto;
            min-width: 310px;
            max-width: 310px;
            font-size: 11px;

            .col-flex-label-selector {
                position: relative;
                select {
                    width: 100%;
                    padding: 10px 5px;
                }
            }

            .col-flex-label-text-container {
                min-width: 310px;
                max-width: 310px;
                white-space: nowrap;
                text-align: center;

                .flex-label-title-big {
                    border: 1px solid transparent;
                    margin: 2px 0;
                    background-color: transparent;
                    color: #bababa;
                    font-size: 28px;
                    font-weight: bold;
                    text-align: left;
                    padding-top: 20px;
                    //text-transform: uppercase;
                }

                .flex-label-title {
                    border: 1px solid transparent;
                    margin: 2px 0;
                    background-color: transparent;
                    color: #333333;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: left;
                    padding-top: 0;
                }

                .flex-label-title-small {
                    border: 1px solid transparent;
                    margin: 2px 0;
                    background-color: transparent;
                    color: #333333;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
                    padding-top: 0;
                }
            }
        }

        .col-flex-field-container {
            border: 1px solid transparent;
            width: auto;
            overflow: auto;
            font-size: 11px;

            .col-flex-field-row-container {
                display: flex;

                .col-flex-field-row {
                    min-width: 170px;
                    max-width: 170px;
                    white-space: nowrap;
                    text-align: center;
                    border: 1px solid transparent;
                    opacity: 0.9;
                    border-left: 0;
                    border-right:0;

                    .flex-row-title-empty-big {
                        border: 1px solid transparent;
                        margin: 2px 0;
                        background-color: transparent;
                        color: #333333;
                        font-size: 28px;
                        font-weight: bold;
                        text-align: left;
                        padding-top: 20px;
                    }

                    .flex-row-title-empty {
                        border: 1px solid transparent;
                        margin: 2px 0;
                        background-color: transparent;
                        color: #333333;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: left;
                        padding-top: 0;
                    }

                    .flex-row-title-empty-small {
                        border: 1px solid transparent;
                        margin: 2px 0;
                        background-color: transparent;
                        color: #333333;
                        font-size: 14px;
                        font-weight: bold;
                        text-align: left;
                        padding-top: 0;
                    }

                    .field-row-label {
                        border: 1px solid #bababa;
                        padding: 10px 20px;
                        text-transform: uppercase;
                        border-left:0;
                    }

                    .field-row-label:first-child.field-row-label {
                        border-left: 1px solid #bababa;
                    }

                    .field-row-label-response {
                        border: 1px solid $andcogreen;
                        color: $andcogreen;
                        background-color: #ffffff;
                        border-left:0;
                    }

                    .field-row-label:first-child.field-row-label-response {
                        border-left: 1px solid $andcogreen;
                    }

                    .field-row-label-published {
                        border: 1px solid $andcogreen;
                        color: #ffffff;
                        background-color: $andcogreen;
                        border-left:0;
                    }

                    .field-row-label:first-child.field-row-label-published {
                        border-left: 1px solid $andcogreen;
                    }

                    .field-row-label-is-out {
                        border: 1px solid rgb(190, 106, 106);
                        color: #ffffff;
                        background-color: rgb(190, 106, 106);
                        border-left:0;
                    }
                    .field-row-label:first-child.field-row-label-is-out {
                        border-left: 1px solid $andcogreen;
                    }
                }

                .col-flex-field-row-active {
                    background-color: rgba(58, 161, 72, 0.1);
                }
                .col-flex-field-row-inactive {
                    background-color: rgba(165, 15, 15, 0.1);
                }
            }
        }

        .col-field-nav {
            border: 1px solid transparent;
            padding: 10px;
            background-color: #bababa;
            margin-top: 2px;
        }
    }

    .flex-label-text {
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        margin: 2px 0;
        background-color: #333333;
        color: #ffffff;
        

        .cel-id {
            width: 40px;
            padding: 5px;
            font-size: 10px;
            text-align: center;
            flex-shrink: 0;
        }
        .cel-text {
            width: 100%;
            text-align: right;
            padding: 5px;
            border-left: 1px solid #ffffff;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                color:red;
                font-size: 9px;
            }
        }
    }

    .flex-label-text-hidden {
        display:none;
    }

    .field-row-value {
        border: 1px solid transparent;
        margin: 2px 0;
        background-color: #ffffff;
        position: relative;
        overflow: visible;

        i.fa-lock {
            position: absolute;
            left: 10px;
            top: 8px;
            color: $andcopurple;
        }

        input, select {
            padding: 5px 5px 5px 5px;
            margin: 0;
            border: 0;
            width:100%;
            border-radius: 0;
            background-color: #ffffff;
            text-align: right;
        }

        input[readonly], select[readonly] {
            padding: 5px 5px 5px 30px;
            color: $andcopurple;
            border: 0;
        }
        input[readonly][type="number"] {
            -moz-appearance: textfield;
        }
        input[readonly][type="number"]::-webkit-outer-spin-button,
        input[readonly][type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .field-calc {
            padding: 5px 5px 5px 30px !important;
        }

        input[data-type="number"] {
            padding: 5px 5px 5px 30px !important;
        }
    }

    .field-row-value-hidden {
        display:none;
    }

    .field-row-value-odd {
        background-color: #e0e0e0;

        i.fa-lock {
            color: $andcopurple;
        }

        input, select {
            background-color: #e0e0e0;
            text-align: right;
        }

        input[readonly], select[readonly] {
            padding: 5px 5px 5px 30px;
            color: $andcopurple;
            border: 0;
            background-color: #f5dcfd;
        }
    }

    .flex-label-vehicle-title-hidden,
    .flex-label-vehicle-hidden,
    .field-row-value-vehicle-hidden,
    .field-row-value-vehicle-empty-hidden {
        display: none;
    }

    .flex-label-driver-title-hidden,
    .flex-label-driver-hidden,
    .field-row-value-driver-hidden,
    .field-row-value-driver-empty-hidden {
        display: none;
    }

    .field-row-value-vehicle {
        position: relative;
        overflow: visible;

        .field-row-value-vehicle-mask {
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            z-index: 20;
            background-color: #fafafa;
        }
    }

    .field-row-value-driver {
        position: relative;
        overflow: visible;

        .field-row-value-driver-mask {
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            z-index: 20;
            background-color: #fafafa;
        }
    }

    .vehicle-visibility-hidden, .driver-visibility-hidden {
        visibility: hidden;
    }

    .time-cel-number {
        display:flex;
        align-items:center;
        justify-content: flex-end;
        .hours-cel-number {
            text-align: right;
            width: 100%;
            padding: 5px 0 5px 5px !important;
        }
        .minutes-cel-number {
            text-align: right;
            width: 1.6em;
            padding: 5px 5px 5px 0 !important;
        }
    }
}

.response-form-col-top-container {

    display:flex;
    align-items: center;
    border:1px solid #bababa;
    width: 100%;
    margin: 10px 2px;
    padding: 15px;

    .flex-label-text {
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        margin: 2px 0;
        background-color: #333333;
        color: #ffffff;
        

        .cel-id {
            width: 40px;
            padding: 5px;
            font-size: 10px;
            text-align: center;
        }
        .cel-text {
            width: 100%;
            text-align: right;
            padding: 5px;
            border-left: 1px solid #ffffff;
            font-weight: bold;

            span {
                color:red;
                font-size: 16px;
            }
        }
    }

    .field-row-value {
        border: 1px solid transparent;
        margin: 2px 0;
        background-color: #ffffff;
        position: relative;
        overflow: visible;

        i.fa-lock {
            position: absolute;
            left: 10px;
            top: 8px;
            color: $andcopurple;
        }

        input, select {
            padding: 5px 5px 5px 5px;
            margin: 0;
            border: 0;
            width:100%;
            border-radius: 0;
            background-color: #ffffff;
            text-align: right;
        }

        input[readonly], select[readonly] {
            padding: 5px 5px 5px 30px;
            color: $andcopurple;
            border: 0;
        }
    }

    .form-col-top-container {
        display:flex;
        border:1px solid transparent;
        width: 100%;
    }

    .col-top-container-y {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-align:center;
        font-size: 32px;
        padding: 0 15px;
        color: #bababa;
    }

    .col-top-container {
        width:100%;
        white-space: nowrap;
        text-align: center;

        i.fa-lock {
            position: absolute;
            left: 10px;
            top: 8px;
            color: #777777;
        }

        input, select {
            padding: 5px 5px 5px 5px;
            margin: 0;
            border: 0;
            width:100%;
            border-radius: 0;
            background-color: #d6d6d6;
            text-align: right;
        }

        input[readonly], select[readonly] {
            padding: 5px 5px 5px 30px;
            color: #777777;
            border: 0;
        }
    }
}

.response-form-btn-top-container {
    position: relative;
    margin: 30px 2px;
    display: flex;
    align-items: center;
    gap: 20px;

    form {
        width:auto;
        padding:0;
    }

    a, button {
        display: block;
        border: 2px solid $andcogreen;
        padding: 10px 50px;
        text-align: center;
        width: auto;
        font-size: 24px;
        text-decoration: none;
    }

    .response-form-btn-publish {
        color: #ffffff;
        background-color: $andcogreen;
    }

    .response-form-btn-extract {
        color: $andcogreen;
        background-color: #ffffff;
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

#public_market_notice.public-market-notice {
    position: fixed;
    bottom: 47px;
    left: 0;
    right: 0;
    z-index: 30;
    background-color: #ffffff;
    display: none;

    .public-market-notice-content {
        position: relative;
        .notice-content-info {
            position: relative;
            padding: 10px;
            text-align: center;
            display: none;
        }

        .notice-content-info-red {
            background-color: $red;
            color: $white;
        }
    }
}

button.btn-fixed-red {
    border: 1px solid $red !important;
    color: $red !important;
    background-color: $white !important;
    &:hover {
        background-color: $white !important;
        color: $red !important;
        background-color: $white !important;
    }
}

.public-market-status-published {
    color: #ffffff !important;
    background-color: $andcogreen !important;
    font-size: 20px !important;
}

#annual_price_offer {
    height: 60px;
    padding-top: 57px;
    padding-bottom: 60px;
}