$andcogreen                   :#92B655;
$andcopurple                  :#604B88;
$bggreycheckboxdark           :#eeeeee;
$blackligth                   :#212529;
$greendark                    :#80a316;
$greenligth                   :#f6ffdb;
$greennotice                  :#40b91b;
$grey                         :#BABABA;
$greyligth                    :#f9fafb;
$orangenotice                 :#f78b34;
$purpledark                   :#5b2580;
$purpleligth                  :#f3e2ff;
$rednotice                    :#d42121;
$white                        :#FFFFFF;
$black                        :#333333;
$red                          :#ff0000
