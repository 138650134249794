.header {
    position: relative;
}
.header-image {
    position: relative;
    overflow: hidden;
    height: 500px;
}

.header-image-slider {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:500px;
    overflow:hidden;
    margin: 0 0 0 0;

    .image-slider {
        position: relative;
        overflow: hidden;

        .image-slider-text {
            position: absolute;
            top:0;
            left:0;
            right:0;
            font-size: 80px;
            color: $white;
            font-weight: bold;
            text-align: center;
            padding: 0;
            background-color: rgba(0,0,0, 0.3);
        }

        img {
            width: 100%;
        }

    }

    .header-center {
        position:absolute;
        background-color: #5b2580;
        border-radius: 7px;
        top: 27%;
        left: 20%;
        width: 200px;
        z-index: 90002;
        color: $white;
        padding: 20px 40px;
        display: block;
        text-transform: uppercase;
        color: #FFFFFF;
    
        &:hover {
            color: #FFFFFF;
        }
    }
}

.home-header-slick-dots {
    position: absolute;
    bottom: 0;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    color: #000000;
    z-index: 60;
    height: 100px;
    background-color: rgba(91, 37, 128, 0.4);
    color: #FFFFFF;
    display: flex;
    justify-content: center;

    li {
        position: relative;
        display: inline-block;
        height: auto;
        width: auto;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            font-size: 16px;
            border: 0;
            background: transparent;
            display: block;
            height: 90px;
            width: auto;
            outline: none;
            line-height: 1;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            padding: 0 20px;
            cursor: pointer;

            &:before {
                position: relative;
                top: 0;
                left: 0;
                content: inherit;
                width: auto;
                height: auto;
                font-family: inherit;
                font-size: 16px;
                line-height: 1;
                text-align: center;
                color: #FFFFFF;
                opacity: 1;
                -webkit-font-smoothing: antialiased;
            }
        }

        &:hover {
            background-color: #5b2580;
        }
    }


    li.slick-active {
        background-color: #5b2580;
    }

}

.header-image-media {
    height: 500px;
    background-image: url('/public/img/media-top-home.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.header-top {
    position: relative;
    font-size: 80px;
    color: $white;
    font-weight: bold;
    text-align: center;
    padding: 20px 10px;
    background-color: rgba(0,0,0, 0.5);
}


.header-bottom {
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    color: $white;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    background-color: rgba(91, 37, 128, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom-item {
        width: auto;
        padding: 0 20px;
        text-align: center;
        cursor: pointer;
        height: 90px;
        /*padding-top: 25px;*/

        &:hover {
            background-color : $purpledark;
        }
    }
}
