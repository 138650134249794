.navbar-admin {
    background-color: $white;
    border-bottom: 1px solid $grey;
    display:flex;
    justify-content: flex-end;
    padding: 0;
    padding-left: 10px;
    margin-bottom: 10px;

    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index:777;

    a.navbar-brand {
        display:block;
    }

    .navbar-collapse {
        position: relative;

        ul.navbar-nav {
            width: 100%;
            padding: 0;
            margin: 0;
            display:flex;
            align-items: center;
            justify-content: flex-end;

            li.nav-item {
                width: auto !important;
                flex-basis:inherit;
                flex-grow: inherit;
                padding: 10px;
                white-space: nowrap;
                border-right: 1px solid $grey;

                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: block;

                }

                .nav-choice-lang {
                    padding: 0;
                }

                
            }

            li.nav-item-full {
                width: 100% !important;
                text-align: left;
            }

            li:last-child {
                border: 0;
            }
        }
    }
}

a.navbar-brand img {
    height: 1.5rem;
}
nav#home-navbar {
    position: fixed;
    top:0;
    left:0;
    right: 0;
    z-index: 98;
    //border-bottom: 1px solid $grey;
    padding: 0 5px;

    li.nav-item {
        align-items: center;
        border-left: 1px solid $grey;
        display: flex;
        justify-content: center;
        text-transform: uppercase;

        a:not(.btn) {
            color: $black;
        }

        a:not(.btn):hover {
            opacity: 0.5;
            text-decoration: none;
            transition: opacity 0.3s;
        }
    }

    li.nav-item-last {
        border:0;
        padding-top: 2px;
        padding-bottom: 2px;

        .btn-group-vertical {
            a.btn {
                margin: 2px 0 2px 0;
                border-radius: 0;
                font-size: 16px;
                //border-top: 0;
                //border-bottom: 0;
            }
        }
    }

    li.nav-item-contact {
        
        a.btn {
            border-radius: 0;
            font-size: 16px;
        }
    }

    li.nav-item-lang {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-create-account {
        background-image: url(/public/img/adhesion.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: 35%;
    }
}
nav#user-navbar #navbarSupportedContent > ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
        padding: 3px 10px;
        white-space: nowrap;

        &:first-child {
            width: 100%;
            border-left: 1px solid #e2e2e2;
            border-right: 1px solid #e2e2e2;
            margin: 0 20px;
        }
    }

    li.tchat-open {
        padding: 5px 10px;
    }

    a.btn-link {
        font-size: 18px ;
        font-weight: 200;
    }

    a.btn-sm {
        font-size: 13px ;
        font-weight: 200;
        padding: 0 15px;
    }

    a.border {
        border-radius:0;
        font-weight: 200;
        padding: 10px 30px;
        color: #757575;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 0.6;
            transition: opacity .3s;
        }
    }
}
