.flex {
    display: flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-start {
    justify-content: flex-start;
}
.flex-end {
    justify-content: flex-end;
}
.flex-center {
    justify-content: center;
}
.flex-item {
    position: relative;
    width: 100%;
}
@for $i from 10 through 100 {
    .flex-item-#{$i}pe {
        width: 1%*$i;
    }
}
.in-flex-item {
    margin: 10px;
}
.flex-align-items {
    align-items: center;
}
