.pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;

    .item-paginate {
        border: 1px solid $grey;
        padding: 5px;
        font-size: 10px;
        background-color: $white;
        padding: 8px;
        margin: 0 0 0 3px;
        text-align: center;
        display:none;
    }

    .item-paginate-visible {
        display: block;
    }

    .item-paginate-selected {
        background-color: $grey;
        color: $white;
    }

    /*.item-paginate-hidden-prev,
    .item-paginate-hidden-next {
        padding: 5px;
        font-size: 10px;
        padding: 0;
        margin: 0 0 0 3px;
        text-align: center;
        display: none;
    }*/


    .item-paginate-prev,
    .item-paginate-next {
        border: 1px solid $grey;
        padding: 5px;
        font-size: 10px;
        background-color: $white;
        padding: 8px;
        margin: 0 0 0 0;
        text-align: center;
    }

    .item-paginate-prev {
        margin: 0 15px 0 0;
    }

    .item-paginate-next {
        margin: 0 0 0 15px;
    }

    .item-paginate-start,
    .item-paginate-end {
        border: 1px solid $grey;
        padding: 5px;
        font-size: 10px;
        background-color: $white;
        padding: 8px;
        margin: 0 0 0 5px;
        text-align: center;
    }

    .item-paginate-start {
        margin: 0 3px 0 0;
    }

    .item-paginate-end {
        margin: 0 0 0 3px;
    }

    .item-paginate-selector {
        background-color: transparent;
        border: 1px solid transparent;
        display:flex;
        width: 100%;
        float: left;
        select {
            font-size: 14px;
            width: 70px;
            margin:0;
        }
        button {
            width: 30px;
            font-size: 11px;
            text-align: center;
            padding: 7px;
            margin: 0;
        }
    }
}

.pagination-start {
    justify-content: flex-start;
}
.pagination-end {
    justify-content: flex-end;
}

.pagination-total {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 10px;
    font-size: 12px;
    text-align: right;

    span {
        padding: 0 3px;
    }
}

.pagination-total-items {
    margin: 0 10px 0 10px;
    font-size: 14px;
    min-width: 100px;
    text-align: right;
}