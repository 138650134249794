.accordion-title {
    position: relative;
    display: flex;
    border: 1px solid $grey;
    background-color: $white;
    margin-top: 5px;

    .accordion-title-icon {
        width: 40px;
        text-align: center;
        border-right: 1px solid $grey;
        padding: 5px;
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;
        &:hover {
            opacity: 0.5;
            transition: opacity .3s;
        }
    }

    .accordion-title-text {
        width: 100%;
        text-align: left;
        padding: 5px;
    }
}

.accordion-title-open {
    .accordion-title-icon {
       i {
           transform: rotate(90deg);
       }
    }
}

.accordion-content {
    position: relative;
    border: 1px solid $grey;
    background-color: $white;
    padding: 10px;
    border-top: 0;
    display: none;
}

.accordion-content-visible {
    display: block;
}