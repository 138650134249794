.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 8888888;
    display: none;

    .modal-title {
        position: relative;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        padding: 10px 5px;
        text-transform: uppercase;
    }

    .modal-close-container {
        position: relative;
        margin: 15px 5px 5px 5px;

        button {
            color: $grey;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.5;
                transition: opacity .3s;
            }
        }
    }
}

.modal-confirm {
    .modal-content {
        width: 400px;
        margin: 15% auto;
        background-color: $white;
        padding: 20px;
        border: 1px solid $grey;
        border-radius: 4px;
        box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -webkit-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
    }

    .modal-body {

        button {
            margin: 5px;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.5;
                transition: opacity .3s;
            }
        }
    }
}

.modal-add-user {

    .modal-content {
        width: 600px;
        margin: 10% auto;
        background-color: $white;
        padding: 20px;
        border: 1px solid $grey;
        border-radius: 4px;
        box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -webkit-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
    }

    .modal-body {

        .flex {

            .modal-add-user-btn {
                display: block;
                width: 100%;
                text-align: center;
                height: 150px;
                margin: 5px;
                line-height: 150px;
                border: 1px solid $grey;
                opacity: 1;
                transition: opacity .3s;
                font-weight: bold;
                font-size: 18px;

                &:hover {
                    opacity: 0.5;
                    transition: opacity .3s;
                }
            }
        }
    }
}

.modal-modal {

    .modal-content {
        /*position: relative;*/
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $white;
        border: 1px solid $grey;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -webkit-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);
        -moz-box-shadow: 0px 3px 28px 3px rgba(0,0,0,0.39);

        .modal-body {
            position: relative;
            img {
                position: relative;
                width: 100%;
            }
            p {
                position:relative;
            }
        }
    }
}
