.modal-edit {
    //margin-left: 65px;
    //padding: 10px;

    .modal-edit-return {
        position: relative;
        margin: 0 5px 5px 5px;

        a {
            border: 1px solid #bebebe;
            color: #bebebe;
            padding: 5px 10px;
            display: inline-block;
            //border-radius: 3px;
            background-color: #ffffff;
            font-size: 12px;
            text-decoration: none;
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                opacity: 0.6;
                transition: opacity .3s;
            }
        }
    }

    .modal-notice-required {
        position: relative;
        padding: 5px 5px 0 5px;
        text-align: right;
        font-size: 12px;
        span {
            color: red;
        }
    }

    form {
        .modal-edit-flex-container {
            display:flex; 
            margin:0;
            margin-bottom: 30px;
            flex-wrap: wrap;
            justify-content: center;
            //align-items: center;
        }

        .modal-edit-container {
            margin: 0 5px 30px 5px;
            border:1px solid #bebebe;
            border-top: 3px solid #bebebe;

            .modal-item-title {
                position: relative;
                width:100%;
                border-bottom: 1px solid #bebebe;
                padding: 10px 5px;
                text-transform: uppercase;
                margin-bottom: 10px;
                font-size: 12px;

                span {
                    color: red;
                    padding: 0 10px;
                    font-size: 16px;
                }
            }

            .modal-item-content {
                padding: 10px;
            }
        }

        label {
            display:block;
        }

        input, select {
            width: 100%;
            text-align:center;
            border: 1px solid #bebebe;
            padding:10px;
            background-color: #ffffff;
        }

        button {
            width: calc(100% - 10px);
            display:block;
            padding:10px;
            margin: 10px 5px;
            border:1px solid #bebebe;
            background-color: #bebebe;
            color:$white;
            margin: 0 auto;
            opacity: 1;
            transition: opacity .3s;

            &:hover {
                opacity: 0.7;
                transition: opacity .3s;
            }
        }

        .modal-edit-col {
            width: 25%;
            min-width: 300px;
        }

        .modal-edit-item {
            position: relative;
            border:1px solid #bebebe;
            margin:5px;
            min-width:255px;
            padding:0;
            font-size: 12px;
            border-top: 3px solid #bebebe;

            .modal-item-title {
                position: relative;
                width:100%;
                border-bottom: 1px solid #bebebe;
                padding: 10px 5px;
                text-transform: uppercase;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                span {
                    color: red;
                    padding: 0 10px;
                    font-size: 16px;
                }

                .text {
                    width: 100%;
                }

                .icon {
                    width: 40px;
                    text-align:center;
                    cursor: pointer;
                }
            }

            small {
                padding: 5px;
                font-size: 11px;
            }

            .modal-item-content {
                padding: 10px;
                min-height: 135px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                label {
                    margin-bottom: 0;
                    margin-top: 5px;
                    text-align:left;
                    width:100%;
                    display: block;
                }

                small {
                    text-align: left;
                    width:100%;
                    padding: 5px 0;
                    font-style: italic;
                }
            }

            .modal-item-page {
                margin: 2px;
                width:100%;

                label {
                    display: flex;
                    align-items: center;
                    border:1px solid #bebebe;

                    .item-page-text {
                        width: 100%;
                        padding: 10px;
                        border-right:1px solid #bebebe;
                    }

                    .item-page-field {
                        width: 40px;
                    }
                }
            }

            .modal-search-flex {
                position: relative;
                display: flex;
                input {
                    width: calc(100% - 40px);
                    border-right: 0;
                }
                button.modal-btn-do-search,
                button.modal-btn-all-do-stt-search,
                button.modal-btn-stt-search {
                    width: 40px;
                    text-align: center;
                }

            }
        }

        .modal-results-remove {
            position: relative;
            border:1px solid #bebebe;
            margin: 0 2px 5px 2px;
            padding: 5px 10px;
            cursor:pointer;
            font-size: 12px;
            opacity: 1;
            transition: opacity .3s;
            text-align: center;

            &:hover {
                opacity: 0.6;
                transition: opacity .3s;
            }
        }

        .modal-results-count {
            position: relative;
            margin: 5px 0;
            padding: 5px 10px;
            font-size: 11px;
        }

        .modal-results-select-all {
            position: relative;
            padding: 3px;
            text-align:center;
            font-size: 11px;
            cursor:pointer;
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                opacity: 0.6;
                transition: opacity .3s;
            }
        }

        .modal-only-do-results,
        .modal-only-all-do-stt-results,
        .modal-only-stt-results {
            position: relative;
            min-height: 0;
            max-height: 135px;
            overflow-x: hidden;
            overflow-y: auto;
            width:100%;

            .modal-loader {
                position: relative;
                text-align:center;
                padding: 10px;

                i {
                    -webkit-animation:spin 1s linear infinite;
                    -moz-animation:spin 1s linear infinite;
                    animation:spin 1s linear infinite;
                }
            }

            .modal-result-item {
                position: relative;
                border: 1px solid #bebebe;
                //font-size: 13px;
                margin: 5px 2px;
                overflow: hidden;
                cursor: pointer;
                opacity: 1;
                transition: opacity .3s;

                label {
                    display: flex;
                    align-items: center;
                    margin: 0;


                    &:hover {
                        opacity: 0.8;
                        transition: opacity .3s;
                    }

                    .item-text-container {
                        width: 100%;
                        border-right:1px solid #bebebe;

                        .item-title {
                            padding: 3px;
                        }

                        .item-text {
                            padding: 0 3px;
                            font-size: 11px;
                        }
                    }

                    .item-field-container {
                        width: 40px;
                        text-align:center;
                    }
                }

                .item-for-all-stt {
                    border-top:1px solid #bebebe;
                    padding: 5px;
                    cursor: pointer;
                    font-size: 11px;

                    &:hover {
                        color: purple;
                    }
                }
            }

        }
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}