#v3_header {
    margin-bottom: 105px;
    #demo_logo {
        position:fixed;
        top:2.2vw;
        margin-left:5vw;
        width:15vw;
        font-size:1vw;
        color:red;
        font-weight:bold;
        text-shadow:0px 0px 1vw rgb(255,0,0);
        z-index:9999;
        pointer-events: none;
        left: 0;
    }
    header {
        margin-bottom: 30px;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        background-color: white;
        > div {
            overflow: hidden;
        }
        .v4_logo_container {
            width: 16.66666667%;
            float: left;
            padding: 0 15px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            .v4_logo_container {
                display: none;
            }
            #user-logo {
                display: none;
            }
        }
        #headerbar {
            float: right;
        }
        #logo img {
            max-width: 100%;
            margin-top:15px;
            max-height:60px; /* fix for IE11 */
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            #user-logo {
                display: none;
            }
        }
        #user-logo {
            float: left;
            width: 8.33333333%;
            min-width: 90px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            line-height: 65px;
        }
        #user-logo img {
            display:inline-block;
            max-width: 100%;
            max-height:65px;
        }
        #login-front {
            float: left;
            padding: 7px 15px 0;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            color: #333;
        }
        #login-front p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
        }
        #login-front a {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-deconnexion.svg');
            background-position: left center;
            background-size: 13px auto;
            background-repeat: no-repeat;
            padding-left: 15px;
        }
        nav {
            float: left;
            text-transform: uppercase;
        }
        @media only screen and (max-width: 767px) {
            .v4_logo_container {
                display: none;
            }
            #user-logo {
                display: none;
            }
            nav {
                display: none;
            }
        }
        nav ul {
            float: left;
            margin: 0;
            padding: 0;
        }
        nav li {
            background-repeat:no-repeat;
            background-position:left 20px center;
            background-size:auto 15px;
            color:#6e6e6e;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            font-size:16px;
            font-weight:bold;
            text-shadow:none;
            display: block;
            float: left;
            border-left: 1px solid #dadada;
        }
        nav li a {
            display: block;
            background-repeat: no-repeat;
            background-position: left 20px center;
            background-size: auto 15px;
            color: #6e6e6e;
            font-size: 16px;
            font-weight: bold;
            text-shadow: none;
            padding: 15px;
            padding-left: 50px;
            line-height: 20px;
        }
        .espace {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-profil.svg');
        }
        .clem_site {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-andco.svg');
        }
        .contact {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-contact.svg');
        }
        .underline {
            text-decoration: underline;
        }
        a:hover {
            color: #97BE1E;
            text-decoration: none;
        }
        li:hover {
            background-color: #eee;
        }
        @media (min-width: 768px) {
            button {
                display: none;
            }
        }
    }
    header::after {
        content: " ";
        width: 100%;
        height: 30px;
        background-repeat: repeat-x;
        background-size: contain;
        position: absolute;
        background-image: url($v3_transporturl + '/app/local/site/skin/images/homebar-do.jpg');
    }
    header.stt {
        #login-front a {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-deconnexion-vert.svg');
        }
        .espace {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-profil-vert.svg');
        }
        .clem_site {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-andco-vert.svg');
        }
        .contact {
            background-image: url($v3_transporturl + '/app/local/site/skin/images/picto-menu-contact-vert.svg');
        }
    }
    header.stt::after {
        background-image: url($v3_transporturl + '/app/local/site/skin/images/homebar-stt.jpg');
    }
}

#v3_footer {
    footer {
        padding: 0;
        margin-top: 1%;
        background-color: #000000;
        background-size: cover;
        display: inline-block;
        line-height: 30px;
        text-align: center;
        width: 100%;
        color: white;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 14px;
        font-weight: normal;
        .badge {
            border-radius: 10px;
            padding: 3px 7px;
            font-size: 12px;
        }
        #menu-block-footer {
            margin-top: 0.5%;
            padding: 0.5%;
            display: flex;
            flex-wrap: wrap;
        }
        .menu-block-footer {
            width: 25%;
            padding-right: 15px;
            padding-left: 15px;
            margin-bottom: 14px;
            h4 {
                margin: 0.5%;
                margin-bottom: 5px;
                text-align: left;
                font-size: 18px;
                font-weight: bold;
            }
        }
        ul {
            margin: 0;
        }
        .btn, li {
            color: white;
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            padding: 0;
            font-size: 14px;
            text-align: left;
            transition: none;
            a {
                display: inline;
            }
        }
        .btn:hover, a:hover {
            color: #515151;
            text-decoration: underline;
        }
        .list-inline {
            padding: 0;
            padding-left: 0;
            margin-left: -5px;
            list-style: none;
            li {
                padding: 0 7px;
            }
        }
    }
}

body #user-navbar,
:not(#v3_header) > header,
:not(#v3_footer) > footer {
    background-color: $red !important;
}
