.buying-page-name {
    position: relative;
    background-color: #97be1e;
    color: #FFFFFF;
    font-size: 25px;
    margin-bottom: 50px;
    padding: 20px;
    font-weight: bold;
}

.buying-top {
    text-align:center;
    margin: -60px auto 10px auto;
    position: relative;
    padding: 10px;
    background-image: url(/public/img/pneu.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    h2 {
        color: #515151;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        margin-right: 1px;
        text-shadow: 4px 2px #cecece;
        text-transform: uppercase;
        border-bottom: 2px dotted #ffffff;
    }
}

.buying-trait-grey {
    position: relative;
    border-bottom: 3px solid $grey;
    width: 150px;
    margin: -30px auto 50px auto;
}

.buying-middle {
    text-align:center;
    margin: 10px auto;
    max-width: 1070px;
    font-size: 24px;
}

.buying-bottom {
    text-align:center;
    margin: 50px auto;
    max-width: 1070px; 
    font-size: 14px;
}