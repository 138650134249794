$v3_transporturl: 'https://andcotransportv3.dev.docker';@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-table/dist/bootstrap-table';
@import '~bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns';
@import '~@fortawesome/fontawesome-free/css/all.css';
// TODO refacto carousel using BS
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import 'common/vars';
@import 'common/colors';
// lines above have to be import in first
@import 'app/dashboard';
@import 'back/appointment';
@import 'back/loader';
@import 'back/logs';
@import 'back/modal_edit';
@import 'back/email';
@import 'back/altares';
@import 'back/modal';
@import 'back/modal_admin';
@import 'back/params';
@import 'back/sidebar';
@import 'back/dashboard';
@import 'back/faq';
@import 'back/search_page';
@import 'common/accordion';
@import 'common/flex';
@import 'common/card';
@import 'common/footer';
@import 'common/form';
@import 'common/global';
@import 'common/forum';
@import 'common/lang';
@import 'front/messenger';
@import 'common/navbar';
@import 'common/pagination';
@import 'common/table';
@import 'common/modal';
@import 'front/dreal';
@import 'front/legalnotice';
@import 'front/header';
@import 'front/home';
@import 'front/solutions';
@import 'front/offers';
@import 'front/contact';
@import 'front/dynamic-modal';
@import 'front/_sustainable_development.scss';
@import 'front/_buying_center.scss';
@import 'front/login';
@import "~leaflet/dist/leaflet.css";
@import '~quill/dist/quill.snow';

@import 'v3';

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "~datatables.net-select-bs4/css/select.bootstrap4.css";

#subcontractor_list_table_length label,
#subcontractor_list_table_filter input {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 200;
}

body {
    background-color: $white;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
}
a {
    color: inherit;
}
.container-fluid {
    padding: 0;
}
.flex-row,
.row {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 5px;
    padding-right: 5px;
}
.flex-row h1,
.row h1 {
    color: $andcopurple;
    margin: 0 auto;
    text-align: center;
}
.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}
.bg-info .btn-link {
    color: #000fff;
}
.bg-danger-v3 {
    background-color: #f2dede !important;
}
.bg-info-v3 {
    background-color: #d9edf7 !important;
}
.bg-success-v3 {
    background-color: #dff0d8 !important;
}
.bg-warning-v3 {
    background-color: #fcf8e3 !important;
}
.btn-green {
    color: $white;
    background-color: $andcogreen;

    &:hover {
        color: $andcogreen;
        background-color: $white;
        border:1px solid $andcogreen;
    }
}
.btn-purple {
    color: $white;
    background-color: $andcopurple;

    &:hover {
        color: $andcopurple;
        background-color: $white;
        border: 1px solid $andcopurple;
    }
}

.btn-black {
    color: $white;
    background-color: $black;

    &:hover {
        color: $black;
        background-color: $white;
        border: 1px solid $black;
    }
}
.text-green {
    color: $andcogreen;
}
.text-purple {
    color: $andcopurple;
}
form legend.required:after,
form label.required:after {
    color: $red;
    content: " *";
}
#scroll-to-top {
    background-color: rgba(32, 32, 32, 0.8);
    border-radius: 3px;
    bottom: 50px;
    color: $white;
    cursor: pointer;
    display: none;
    padding: 15px;
    position: fixed;
    right: 20px;
    text-align: center;
    z-index: 900;
}

#map {
    position: relative;
    min-height: 350px;
    min-width: 350px;
}

#document_upload_edit td > div.input-group {
    max-width: 290px;
}
#document-management-primecontractor-table {
    td:first-child {
        min-width:200px;
    }
    td:first-child .col {
        padding:0;
    }
    .pie-chart {
        width: 75px;
        height: 75px;
        background-image: conic-gradient(red 100%, black);
        border-radius: 50%
    }
}

#society_show .col-4:not(:last-child) {
    border-right: 1px solid #BABABA;
}

/* TODO clean following using BS */
.main {
    padding-top: 10rem;
}
@media all and (min-width: 1500px)
{
    .main {
        padding-top: 7rem;
    }
}

@media all and (max-width: 992px)
{
    #home-navbar {

        .navbar-collapse {

            .navbar-nav {

                li.nav-item {
                    border: 1px solid $grey;
                    margin: 2px;
                    padding: 10px 0;
                    border-radius: 4px;
                }

                li.nav-item-btn {
                    a.btn-create-account {
                        background-size: 25%;
                        padding: 10px 0;
                    }
                }
            }
        }
    }

    .header-image {

        max-height: 250px;

        .header-image-slider {
            max-height: 250px;

            .image-slider {

                .image-slider-text {
                    /*top: 40%;*/
                    font-size: 22px;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .header-center {
                display: none;
            }
        }
    }

    .home-middle {

        .home-middle-img {

            background-size: contain;
            max-height: inherit;

            h2 {
                padding-top: 50px;
                font-size: 30px;
            }
            p {
                font-size: 18px;
            }
        }
    }

    .mac-middle-slider {

        .mac-middle-slider-bg {
            width: 95%;
        }
    }

    .home-middle-img-full {

        .middle-slider-items {

            background-size: contain;
            height: inherit;

            ul.mac-middle-slick-dots {
                height: inherit;
            }
        }
    }

    .home-about {

        text-align: center;

        .home-about-flex {

            .home-about-flex-jj {

                width: 100%;
                display: block;

                .home-about-flex-img {
                    /*display:none;*/
                    position: relative;
                    left: inherit;
                    bottom: inherit;
                    width: 100%;
                    margin-bottom: -30px;
                }
                .home-about-flex-text {
                    position: relative;
                    width: 100%;

                }
            }

            .home-about-flex-logo {
                width: 100%;

                .home-about-logo-container {
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

    }

    .cube {
        height: inherit;
        width: inherit;

        .cube-item {
            width: 80%;
            margin: 10px;
        }
    }

    .cube-purple {
        border: 0;
    }

    .cube-green {
        border: 0;
    }

    .dreal-page-name {
        text-align: center;
    }


    .dreal-top {
        background-size: cover;
        h2 {
            font-size: 20px;
        }
    }

    .dreal-block {
        .cube {
            height: inherit;
            width: inherit;

            .cube-item {
                width: 100%;
            }
        }
    }

    .dreal-middle-text {
        text-align: justify;
        padding: 10px;

        ul {
            text-align: left;
        }
    }

    .solution-page-name {
        text-align: center;
    }

    .solution-middle {

        .mac-middle-slider {

            .mac-middle-slider-bg {
                width: 100%;

                .mac-points {
                    display: none;
                }
            }

        }
    }

    .parallax-1 {

        .parallax-block-1 {
            p {
                width: inherit;
            }
        }

    }

    .offer-page-name {
        text-align: center;
    }

    .offer-middle {

        .mac-middle-slider {

            .mac-middle-slider-bg {
                width: 100%;

                .mac-points {
                    display: none;
                }
            }
        }
    }

    .development-page-name {
        text-align: center;
    }

    .development-top {
        background-size: cover;
        h2 {
            font-size: 20px;
        }
    }

    .development-top-text {
        .top-text-img {
            width: 100%;
            text-align: center;

            img {
                width: 100%;
            }
        }

        .top-text-text {
            width: 100%;
            text-align: justify;
        }
    }

    .development-top-btn {
        padding: 0 10px;
    }

    .development-middle-img {
        text-align: center;
        img {
            width: 90%;
        }
    }

    .development-middle-text {
        text-align:justify;
        padding: 0 10px;

        ul {
            text-align: left;
        }
    }

    .development-bottom-flex {
        .bottom-flex-item {
            width: 100%;
            margin: 0 0 20px 0;

            .flex-item-text {
                width: 100%;
                padding: 0 20px;
            }
        }
    }

    .buying-page-name {
        text-align: center;
    }

    .buying-top {
        background-size: cover;
        h2 {
            font-size: 20px;
        }
    }

    .create-account-main {

        .form-account-container {
            margin: 0 10px;
        }
        .form-account-block-flex {
            min-width: 100%;
            width: 100%;
        }
    }

    .login-resetpassword {
        width: 100%;
    }

    .login-options {
        flex-wrap: wrap;
    }

    .contact-form-container {
        margin: 0 15px 30px 15px;
    }
}
