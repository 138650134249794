.modal-pages-search {
    position:relative;
    /*border: 1px dotted grey;*/
    input {
        border: 1px solid $grey;
        border-bottom: 0;
    }
}

.modal-pages-container {
    display:flex; 
    flex-wrap:wrap;
    justify-content:center;
    border: 1px solid $grey;
    max-height: 300px;
    overflow-y:auto;
    background-color: $greyligth;

    .modal-pages-item {
        border:1px solid $grey;
        margin: 2px;
        display:flex;
        align-items:center;
        width:24%;
        background-color: $white;

        .modal-pages-item-checkbox {
            width:50px;
            padding: 3px;
            text-align:center;
        }
        .modal-pages-item-text {
            border-left:1px solid $grey;
            width:calc(100% - 50px);
            padding: 0;
            font-size:13px;

            .item-text-key {
                padding: 5px;
                text-transform: uppercase;
                border-bottom:1px dashed $grey;
            }

            .item-text-path {
                padding: 5px;
                font-size: 12px;
            }
        }
    }
}

.modal-email-template {
    position:absolute;
    top: 10%;
    width: 600px;
    left: calc(50% - 300px);
    background-color: $white;
    border:1px solid $grey;
    z-index: 77;
    display: none;
    background-color: #dddddd;

    .modal-email-template-header {
        display:flex;
        justify-content:flex-start;
        align-items: center;
        border-bottom: 1px solid $grey;
        background-color: $white;

        .modal-template-header-title {
            width:100%;
            padding: 10px;
            font-weight: bold;
            font-size: 18px;
        }

        .modal-template-header-close {
            width: auto;
            padding: 10px; 
            cursor: pointer;
            opacity: 1;
            transition: opacity .3s;
            &:hover {
                opacity: 0.5;
                transition: opacity .3s;
            }
        }
    }

    .modal-email-template-content {
        position: relative;
        background-color: $white;

        .modal-email-template-content-image {
            position: relative;
            padding: 10px;
            text-align: center;
            img {
                width: 100%;
            }
        }

        .modal-email-template-content-body {
            position: relative;
            padding: 10px;
            text-align: center;
        }    
    }

    .modal-email-template-footer {
        position: relative;
        text-align: center;
        font-size: 13px;
        padding: 10px;
    }
}