.dynamic-modal {
    z-index: 888;
    position: fixed;
    background-color: #FFFFFF;
    overflow:visible;
    display:none;
    border-radius: 4px;
    border: 1px solid #333333;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;

    .dynamic-modal-close {
        position: relative;
        overflow: visible;
        display:flex;
        justify-content: flex-end;
        font-size: 22px;

        i.dynamic-modal-close-btn {
            top: -5px;
            right: -5px;
            cursor: pointer;
            background-color: #FFFFFF;
            padding: 5px;
            background-color: #333333;
            color: #FFFFFF;
            border-left: 1px solid #333333;
            border-bottom: 1px solid #333333;
            border-radius: 0 0 0 4px;
        }
    }

    .dynamic-modal-title {
        position: relative;
        padding: 10px;
        font-size: 22px;
        font-weight: bold;
    }

    .dynamic-modal-content {
        position: absolute;
        top:100px;
        left: 0;
        right:0;
        bottom: 0;
        padding:10px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}